import React from 'react';
import PropTypes from 'prop-types';
import MatGrid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles({
  bordered: {
    border: '0.5px solid black',
  },
  padded: {
    padding: 5,
  },
});

const Grid = ({ children, item, className, noBorder, ...props }) => {
  const classes = styles();

  return (
    <MatGrid
      className={`${!noBorder ? classes.bordered : ''} ${item ? classes.padded : ''} ${className}`}
      item={item}
      {...props}
    >
      {children}
    </MatGrid>
  );
};

Grid.defaultProps = {
  children: null,
  item: false,
  className: '',
  noBorder: false,
};

Grid.propTypes = {
  children: PropTypes.node,
  item: PropTypes.bool,
  className: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default Grid;
