import { AUTH_VALIDATE, AUTH_VALIDATE_CLEAR, AUTH_LOGIN } from '../actions/types';

const DEFAULT_STATE = {
  valid: null,
  userId: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return { ...state, userId: action.payload };
    case AUTH_VALIDATE:
      return { ...state, valid: action.payload };
    case AUTH_VALIDATE_CLEAR:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
