import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { View, StyleSheet } from '@react-pdf/renderer';

import Text from './Text';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    fontSize: 8,
  },
  leftFields: {
    left: 65,
  },
  rightItemFields: {
    left: 333,
  },
  rightSubItemFields: {
    left: 350,
  },
});

const item3Styles = key => {
  switch (key) {
    case 'securitiesSalesman':
    case 'otherEntitiesManagingSecurities':
      return { marginBottom: 12 };
    case 'otherEntitiesAdministeringCurrency':
      return { marginBottom: 17 };
    default:
      return { marginBottom: 3.625 };
  }
};

const item6Styles = key => {
  switch (key) {
    case 'actingFormationAgent':
      return { marginBottom: 3.625 };
    default:
      return { marginBottom: 53 };
  }
};

const item7Styles = key => {
  switch (key) {
    case 'bankManagement':
      return { marginBottom: 25 };
    case 'contributionsOrganization':
      return { marginBottom: 30 };
    default:
      return { marginBottom: 18 };
  }
};

const RadioSwitch = ({ style, select }) => (
  <View style={[styles.ovleray, { flexDirection: 'row', alignItems: 'center', ...style }]}>
    <Text style={{ fontSize: 10, marginRight: 2, marginLeft: 1 }}>O</Text>
    <Text style={{ fontSize: 12, marginRight: 17 }}>Yes</Text>
    <Text style={{ fontSize: 10, marginRight: 2 }}>O</Text>
    <Text style={{ fontSize: 12 }}>No</Text>
    <Text style={{ position: 'absolute', fontSize: 16, bottom: 17, left: select ? 2 : 46 }}>{'\u2022'}</Text>
  </View>
);

const Page2Overlay = ({ fields }) => (
  <View style={[styles.overlay]}>
    <Text style={[styles.overlay, { left: 110, top: 59, width: 440, alignItems: 'flex-start' }]} bold>
      {fields.companyName}
    </Text>
    <RadioSwitch style={{ left: 420, top: 75 }} select={fields.amlaCoverage} />
    <View style={[styles.overlay, styles.leftFields, { top: 122 }]}>
      {_.map(fields.item1, (subItem, key) => (
        <Text key={key} style={{ marginBottom: 3.625 }}>
          {subItem ? 'X' : ' '}
        </Text>
      ))}
    </View>
    <View style={[styles.overlay, styles.leftFields, { top: 293 }]}>
      {_.map(fields.item2, (subItem, key) => (
        <Text key={key} style={{ marginBottom: 3.625 }}>
          {subItem ? 'X' : ' '}
        </Text>
      ))}
    </View>
    <View style={[styles.overlay, styles.leftFields, { top: 442 }]}>
      {_.map(fields.item3, (subItem, key) => (
        <Text key={key} style={item3Styles(key)}>
          {subItem ? 'X' : ' '}
        </Text>
      ))}
    </View>
    <Text style={[styles.overlay, styles.rightItemFields, { top: 134 }]}>{fields.item4 && 'X'}</Text>
    <Text style={[styles.overlay, styles.rightItemFields, { top: 203 }]}>{fields.item5 && 'X'}</Text>
    <View style={[styles.overlay, styles.rightSubItemFields, { top: 307 }]}>
      {_.map(fields.item6, (subItem, key) => (
        <Text key={key} style={item6Styles(key)}>
          {subItem ? 'X' : ' '}
        </Text>
      ))}
    </View>
    <View style={[styles.overlay, styles.rightSubItemFields, { top: 488 }]}>
      {_.map(fields.item7, (subItem, key) => (
        <Text key={key} style={item7Styles(key)}>
          {subItem ? 'X' : ' '}
        </Text>
      ))}
    </View>
    <Text style={[styles.overlay, styles.rightItemFields, { top: 621 }]}>{fields.item8 && 'X'}</Text>
    <Text style={[styles.overlay, { left: 369, top: 633, width: 184, height: 48 }]} bold>
      {fields.natureOfBusiness}
    </Text>
    <RadioSwitch style={{ left: 415, top: 680 }} select={fields.cddCompliance} />
  </View>
);

RadioSwitch.defaultProps = {
  style: {},
  select: false,
};

RadioSwitch.propTypes = {
  style: PropTypes.shape(),
  select: PropTypes.bool,
};

Page2Overlay.propTypes = {
  fields: PropTypes.shape().isRequired,
};

export default Page2Overlay;
