import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withTheme, withStyles } from '@material-ui/styles';

import { Paper, Loading } from '../components/CustomComponents';
import PageLinks from '../components/PageLinks';
import Validate from '../components/Validate';

import { clearPage, fetchFields } from '../actions';

const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
  box: {
    width: 'calc(100vw/3)',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    width: '6rem',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  loadingPaper: {
    height: 'calc(100vh/3)',
    width: 'calc(100vw/3)',
  },
});

class PageSelection extends Component {
  state = {
    loading: false,
  };

  componentDidMount = () => {
    this.setState({ loading: true }, async () => {
      this.props.pageClear();
      await this.props.fetch(this.props.match.params.id);
      this.setState({ loading: false });
    });
  };

  render = () => (
    <Validate>
      <div className={this.props.classes.root}>
        <Box className={this.props.classes.box}>
          {!this.state.loading ? (
            <Paper>
              <Grid container spacing={3} direction="column">
                <Grid item className={this.props.classes.headerContainer}>
                  <Typography variant="h3">Page Selection</Typography>
                </Grid>
                <Grid item>
                  <PageLinks />
                </Grid>
                <Grid item className={this.props.classes.buttonContainer}>
                  <Button
                    variant="contained"
                    onClick={() => this.props.history.push('/companies')}
                    className={this.props.classes.button}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <Loading className={this.props.classes.loadingPaper} />
          )}
        </Box>
      </div>
    </Validate>
  );
}

PageSelection.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  classes: PropTypes.shape().isRequired,
  pageClear: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
};

const mapActionsToProps = {
  pageClear: clearPage,
  fetch: fetchFields,
};

const themedComponent = withTheme(PageSelection);
const styledComponent = withStyles(styles)(themedComponent);
const routedComponent = withRouter(styledComponent);

export default connect(null, mapActionsToProps)(routedComponent);
