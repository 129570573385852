import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';

import { Loading } from './CustomComponents';

import { validateUser, logoutUser } from '../actions';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
  paper: {
    width: 'calc(100vw/3)',
    height: 'calc(100vh/3)',
  },
};

class Validate extends Component {
  state = {
    loading: false,
  };

  componentDidMount = async () => {
    if (!this.props.valid && !_.isNil(localStorage.getItem('authToken'))) {
      this.setState({ loading: true }, async () => {
        await this.props.validate();
        if (this.props.valid) this.setState({ loading: false });
        else {
          await this.props.logout();
          this.props.history.push('/');
        }
      });
    } else if (_.isNil(localStorage.getItem('authToken'))) {
      await this.props.logout();
      this.props.history.push('/');
    }
  };

  render = () =>
    this.state.loading ? (
      <Box className={this.props.classes.root}>
        <Loading className={this.props.classes.paper} />
      </Box>
    ) : (
      this.props.children
    );
}

Validate.defaultProps = {
  children: null,
  valid: null,
  validate: () => {},
  logout: () => {},
};

Validate.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  valid: PropTypes.bool,
  validate: PropTypes.func,
  logout: PropTypes.func,
};

const mapStateToProps = ({ auth: { valid } }) => ({
  valid,
});
const mapActionsToProps = {
  validate: validateUser,
  logout: logoutUser,
};

const routedComponent = withRouter(Validate);
const styledComponent = withStyles(styles)(routedComponent);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
