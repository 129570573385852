import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';

import { Grid } from '../../CustomComponents';
import {
  PageEditTextField as TextField,
  PageEditDatePicker as CustomDatePicker,
  PageEditNumberField as NumberField,
  Select,
} from '../../FormComponents';

import { DATE_FORMAT } from '../../../globals';

const DatePicker = withStyles({
  root: {
    '&': {
      margin: 0,
    },
    '& > .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& > .MuiOutlinedInput-adornedEnd > .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
    '& > .MuiOutlinedInput-adornedEnd > .MuiInputAdornment-positionEnd > .MuiButtonBase-root': {
      paddingLeft: 0,
    },
  },
})(CustomDatePicker);

class BeneficialOwner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.beneficialOwner.name,
      address: props.beneficialOwner.address,
      nationality: props.beneficialOwner.nationality,
      dateOfBirth: props.beneficialOwner.dateOfBirth,
      tin: props.beneficialOwner.tin,
      percentOfOwnership: props.beneficialOwner.percentOfOwnership,
      type: props.beneficialOwner.type,
      category: props.beneficialOwner.category,
    };
  }

  changeField = (name, value) => this.setState({ [name]: value }, () => this.props.setValue(this.state));

  render = () => (
    <Grid container noBorder>
      <Grid item xs={2}>
        <TextField
          value={this.state.name}
          placeholder="Name"
          onChange={name => this.changeField('name', name)}
          variant="outlined"
          displayError={this.props.displayError}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          value={this.state.address}
          placeholder="Address"
          onChange={address => this.changeField('address', address)}
          variant="outlined"
          displayError={this.props.displayError}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          value={this.state.nationality}
          placeholder="Nationality"
          onChange={nationality => this.changeField('nationality', nationality)}
          variant="outlined"
          displayError={this.props.displayError}
        />
      </Grid>
      <Grid item xs={2}>
        <DatePicker
          value={this.state.dateOfBirth}
          placeholder={DATE_FORMAT}
          onChange={dateOfBirth => this.changeField('dateOfBirth', dateOfBirth)}
          displayError={this.props.displayError}
        />
      </Grid>
      <Grid item xs={1}>
        <TextField
          value={this.state.tin}
          placeholder="TIN"
          onChange={tin => this.changeField('tin', tin)}
          variant="outlined"
          displayError={this.props.displayError}
        />
      </Grid>
      <Grid item xs={1}>
        <NumberField
          style={{ width: 'calc(100% - 2px)', fontSize: 17 }}
          value={this.state.percentOfOwnership}
          placeholder="%"
          onChange={percentOfOwnership => this.changeField('percentOfOwnership', percentOfOwnership)}
          displayError={this.props.displayError}
        />
      </Grid>
      <Grid
        item
        xs={1}
        style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center' }}
      >
        <Select value={this.state.type} onChange={type => this.changeField('type', type)}>
          <MenuItem value="D">D</MenuItem>
          <MenuItem value="I">I</MenuItem>
          <MenuItem value="">_</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={1}>
        <TextField
          value={this.state.category}
          placeholder="Category"
          onChange={category => this.changeField('category', category)}
          variant="outlined"
          displayError={this.props.displayError}
        />
      </Grid>
    </Grid>
  );
}

BeneficialOwner.defaultProps = {
  beneficialOwner: {
    name: '',
    address: '',
    nationality: '',
    dateOfBirth: null,
    tin: '',
    percentOfOwnership: '',
    type: '',
    category: '',
  },
  setValue: () => {},
  displayError: false,
};

BeneficialOwner.propTypes = {
  beneficialOwner: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    nationality: PropTypes.string,
    dateOfBirth: PropTypes.shape(),
    tin: PropTypes.string,
    percentOfOwnership: PropTypes.string,
    type: PropTypes.string,
    category: PropTypes.string,
  }),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

export default BeneficialOwner;
