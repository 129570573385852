import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { KeyboardDatePicker as MatKeyboardDatePicker } from '@material-ui/pickers';
import { withStyles, makeStyles } from '@material-ui/styles';

import { DATE_FORMAT } from '../../globals';

const KeyboardDatePicker = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 27,
    },
  },
})(MatKeyboardDatePicker);

const styles = makeStyles({
  error: {
    '& > .MuiFormControl-root > .MuiOutlinedInput-root > .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
  },
});

const PageEditDatePicker = ({ optional, displayError, ...props }) => {
  const classes = styles();

  return (
    <div className={_.isEmpty(props.value) && displayError && !optional ? classes.error : ''}>
      <KeyboardDatePicker
        disableToolbar
        inputVariant="outlined"
        variant="inline"
        format={DATE_FORMAT}
        margin="normal"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        autoOk
        {...props}
      />
    </div>
  );
};

PageEditDatePicker.defaultProps = {
  displayError: false,
  optional: false,
  value: '',
};

PageEditDatePicker.propTypes = {
  displayError: PropTypes.bool,
  optional: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
};

export default PageEditDatePicker;
