import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Checkbox } from '../../FormComponents';

const styles = makeStyles({
  input: {
    position: 'absolute',
    margin: 0,
  },
});

const Item6 = ({ fields, onChange }) => {
  const classes = styles();

  return (
    <>
      <Checkbox
        className={classes.input}
        style={{ left: 740, top: 655 }}
        checked={fields.actingFormationAgent}
        onChange={actingFormationAgent => onChange('actingFormationAgent', actingFormationAgent)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 740, top: 680 }}
        checked={fields.actingDirector}
        onChange={actingDirector => onChange('actingDirector', actingDirector)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 740, top: 814 }}
        checked={fields.providingRegisteredOffice}
        onChange={providingRegisteredOffice => onChange('providingRegisteredOffice', providingRegisteredOffice)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 740, top: 945 }}
        checked={fields.actingNominee}
        onChange={actingNominee => onChange('actingNominee', actingNominee)}
      />
    </>
  );
};

Item6.defaultProps = {
  fields: {},
  onChange: () => {},
};

Item6.propTypes = {
  fields: PropTypes.shape(),
  onChange: PropTypes.func,
};

export default Item6;
