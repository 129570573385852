import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import DependentFields from '../DependentFields';
import { numberFormat } from '../CustomComponents';

import { hasInput, convertToNumber } from '../../services/helpers';

const styles = () => ({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginBottom: 3.5,
  },
});

class SubscribedCapital extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nationality: this.props.value.nationality,
      numberOfStockholders: this.props.value.numberOfStockholders,
      typeOfShare: props.value.typeOfShare,
      numberOfShares: props.value.numberOfShares,
      numberOfSharesPublic: props.value.numberOfSharesPublic,
      parStatedValue: props.value.parStatedValue,
      total: props.value.total,
    };
  }

  computeTotal = field => {
    if (_.includes(['numberOfShares', 'numberOfSharesPublic', 'parStatedValue'], field)) {
      if (hasInput(this.state.numberOfShares) && hasInput(this.state.parStatedValue)) {
        return _.multiply(
          _.sum(
            _.compact([convertToNumber(this.state.numberOfShares), convertToNumber(this.state.numberOfSharesPublic)]),
          ),
          convertToNumber(this.state.parStatedValue),
        );
      }
      return null;
    }
    return this.state.total;
  };

  render = () => (
    <div className={this.props.classes.container}>
      <DependentFields
        stock
        options={[
          this.props.foreign && {
            type: 'text',
            field: 'nationality',
            value: this.state.nationality,
            style: { width: 106, marginRight: 25 },
          },
          {
            type: 'number',
            field: 'numberOfStockholders',
            value: this.state.numberOfStockholders,
            style: { width: 72, marginRight: 26 },
          },
          { type: 'text', field: 'typeOfShare', value: this.state.typeOfShare, style: { width: 156, marginRight: 35 } },
          {
            type: 'number',
            field: 'numberOfShares',
            value: this.state.numberOfShares,
            style: { width: 90, marginRight: 35 },
          },
          {
            type: 'number',
            field: 'numberOfSharesPublic',
            value: this.state.numberOfSharesPublic,
            style: { width: 90, marginRight: 35 },
            optional: true,
          },
          {
            type: 'number',
            field: 'parStatedValue',
            value: this.state.parStatedValue,
            style: { width: 90, marginRight: 22 },
          },
        ]}
        onChange={(field, value) =>
          this.setState({ [field]: value }, () => {
            const total = this.computeTotal(field);
            this.setState({ total }, () => {
              this.props.onChange({
                [this.props.index]: this.props.foreign ? this.state : _.omit(this.state, 'nationality'),
              });
            });
          })
        }
        displayError={this.props.displayError}
      />
      <Typography style={{ lineHeight: 1, width: 196, marginRight: 41, textAlign: 'right' }}>
        {numberFormat(this.state.total)}
      </Typography>
      <Typography style={{ lineHeight: 1 }}>
        {numberFormat(
          this.props.percentageRoundOff ? Math.round(this.props.value.percentage) : this.props.value.percentage,
        ) || null}
      </Typography>
    </div>
  );
}

SubscribedCapital.defaultProps = {
  value: {},
  onChange: () => {},
  index: 0,
  displayError: false,
  foreign: false,
  percentageRoundOff: false,
};

SubscribedCapital.propTypes = {
  classes: PropTypes.shape().isRequired,
  value: PropTypes.shape({
    nationality: PropTypes.string,
    numberOfStockholders: PropTypes.string,
    typeOfShare: PropTypes.string,
    numberOfShares: PropTypes.string,
    numberOfSharesPublic: PropTypes.string,
    parStatedValue: PropTypes.string,
    total: PropTypes.number,
    percentage: PropTypes.number,
  }),
  onChange: PropTypes.func,
  index: PropTypes.number,
  displayError: PropTypes.bool,
  foreign: PropTypes.bool,
  percentageRoundOff: PropTypes.bool,
};

export default withStyles(styles)(SubscribedCapital);
