import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import fp from 'lodash-fp';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withTheme, withStyles } from '@material-ui/styles';

import { selectPage } from '../actions';

import { PAGES } from '../globals';

const styles = () => ({
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '7rem',
  },
});

class PageLinks extends Component {
  redirectHandler = page => {
    this.props.pageSelect(page);
    this.props.history.push(`/${this.props.match.params.id}/page-edit`);
  };

  render = () => (
    <Grid container spacing={3} className={this.props.classes.centered}>
      {fp.map(
        page => (
          <Grid item lg={4} className={this.props.classes.centered} key={page}>
            <Button
              variant="contained"
              onClick={() => this.redirectHandler(page)}
              color="primary"
              className={this.props.classes.button}
            >
              {page}
            </Button>
          </Grid>
        ),
        PAGES,
      )}
      <Grid item lg={4} />
    </Grid>
  );
}

PageLinks.propTypes = {
  pageSelect: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  classes: PropTypes.shape({
    centered: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape().isRequired,
};

const themedComponent = withTheme(PageLinks);
const styledComponent = withStyles(styles)(themedComponent);
const routedComponent = withRouter(styledComponent);

const mapActionsToProps = {
  pageSelect: selectPage,
};

export default connect(null, mapActionsToProps)(routedComponent);
