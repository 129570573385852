import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { DATE_FORMAT } from '../../globals';

const DatePicker = props => (
  <KeyboardDatePicker
    disableToolbar
    inputVariant="outlined"
    variant="inline"
    format={DATE_FORMAT}
    margin="normal"
    KeyboardButtonProps={{
      'aria-label': 'change date',
    }}
    autoOk
    {...props}
  />
);

export default DatePicker;
