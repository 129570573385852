import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';
import _ from 'lodash';

import { PageEditTextField as TextField, PageEditNumberField as NumberField, Select } from '../FormComponents';

const StockTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 19,
    },
  },
})(TextField);

const DependentFields = ({ options, stock, onChange, displayError }) => {
  const checkErrors = () => {
    let hasEmptyField = false;
    let allEmptyFields = false;

    _.map(options, option => {
      if (_.isEmpty(option.value) || _.isNull(option.value)) hasEmptyField = true;
      else allEmptyFields = true;
    });

    return hasEmptyField && allEmptyFields && displayError;
  };

  const errors = checkErrors();

  return _.map(options, option => {
    const commonProps = {
      key: option.field,
      value: option.value,
      style: option.style,
      onChange: value => onChange(option.field, value),
      displayError: errors,
      optional: !_.isUndefined(option.optional) && option.optional,
    };

    const textProps = { variant: 'outlined', ...commonProps };

    switch (option.type) {
      case 'text':
        return stock ? <StockTextField {...textProps} /> : <TextField {...textProps} />;
      case 'number':
        return <NumberField {...commonProps} />;
      case 'select':
        return (
          <Select {...commonProps}>
            {_.map(option.options, optionsItem => (
              <MenuItem value={optionsItem} key={optionsItem}>
                {optionsItem !== '' && !_.isNil(optionsItem) ? optionsItem : '_'}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        return null;
    }
  });
};

DependentFields.defaultProps = {
  options: [],
  stock: false,
  onChange: () => {},
  displayError: false,
};

DependentFields.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        type: PropTypes.string,
        field: PropTypes.string,
        value: PropTypes.string,
        style: PropTypes.shape(),
      }),
    ]),
  ),
  stock: PropTypes.bool,
  onChange: PropTypes.func,
  displayError: PropTypes.bool,
};

export default DependentFields;
