import React, { Component } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import TextField from './TextField';

class PasswordTextField extends Component {
  state = {
    showPassword: false,
  };

  render = () => (
    <TextField
      {...this.props}
      type={this.state.showPassword ? 'text' : 'password'}
      endadornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={event => {
              event.preventDefault();
              this.setState(({ showPassword }) => ({ showPassword: !showPassword }));
            }}
          >
            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
}

export default PasswordTextField;
