import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Checkbox } from '../../FormComponents';

const styles = makeStyles({
  input: {
    position: 'absolute',
    margin: 0,
  },
});

const Item7 = ({ fields, onChange }) => {
  const classes = styles();

  return (
    <>
      <Checkbox
        className={classes.input}
        style={{ left: 740, top: 1045 }}
        checked={fields.clientMoneyManagement}
        onChange={clientMoneyManagement => onChange('clientMoneyManagement', clientMoneyManagement)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 740, top: 1101 }}
        checked={fields.bankManagement}
        onChange={bankManagement => onChange('bankManagement', bankManagement)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 740, top: 1172 }}
        checked={fields.contributionsOrganization}
        onChange={contributionsOrganization => onChange('contributionsOrganization', contributionsOrganization)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 740, top: 1251 }}
        checked={fields.juridicalPersonManagement}
        onChange={juridicalPersonManagement => onChange('juridicalPersonManagement', juridicalPersonManagement)}
      />
    </>
  );
};

Item7.defaultProps = {
  fields: {},
  onChange: () => {},
};

Item7.propTypes = {
  fields: PropTypes.shape(),
  onChange: PropTypes.func,
};

export default Item7;
