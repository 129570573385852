import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Document, Page, Image, PDFViewer } from '@react-pdf/renderer';
import { withTheme } from '@material-ui/styles';

import Page1Overlay from './Page1Overlay';
import Page2Overlay from './Page2Overlay';
import Page3Overlay from './Page3Overlay';
import Page5to7Overlay from './Page5to7Overlay';
import Page4Overlay from './Page4Overlay';
import Page8Overlay from './Page8Overlay';
import Page9Overlay from './Page9Overlay';
import BODOverlay from './BODOverlay';

import { PAGES } from '../../globals';

const pdfViewerWidth = () => {
  const width = window.innerWidth;

  if (width > 1000) return '70vw';

  if (width > 740) return '80vw';

  return '100vw';
};

const styles = {
  pdfViewer: {
    width: pdfViewerWidth(),
    height: 'calc(100vh - 55px)',
    zIndex: 1,
  },
};

const PDFRender = ({ currentPage, initialData, page1, page2, page3, page4, page5to7, page8, page9, bod, src, all }) => {
  const renderOverlay = (page, index = null) => {
    switch (page) {
      case 'Page 1':
        return <Page1Overlay fields={{ ...page1, ...initialData }} />;
      case 'Page 2':
        return <Page2Overlay fields={{ ...page2, ...initialData }} />;
      case 'Page 3':
        return <Page3Overlay fields={{ ...page3, ...initialData }} />;
      case 'Page 4':
        return <Page4Overlay fields={{ ...page4, ...initialData }} />;
      case 'Page 5-7':
        return (
          <Page5to7Overlay
            fields={{ ...initialData, ...page5to7, stockholders: _.chunk(_.values(page5to7.stockholders), 7)[index] }}
          />
        );
      case 'Page 8':
        return <Page8Overlay fields={{ ...page8, ...initialData }} />;
      case 'Page 9':
        return <Page9Overlay fields={{ ...page9, ...initialData }} />;
      case 'BOD':
        return <BODOverlay fields={{ ...initialData, ..._.pick(page1, 'secRegistrationNumber'), ...bod }} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <PDFViewer style={styles.pdfViewer}>
        {!all ? (
          <Document title={currentPage}>
            {currentPage !== 'Page 5-7' ? (
              <Page>
                <Image src={`/${src}`} />
                {renderOverlay(currentPage)}
              </Page>
            ) : (
              _.times(3, pageIndex => (
                <Page key={pageIndex}>
                  <Image src={`/page_${_.toString(pageIndex + 5)}_GIS.png`} />
                  {renderOverlay(currentPage, pageIndex)}
                </Page>
              ))
            )}
          </Document>
        ) : (
          <Document title="GIS form">
            {_.map(PAGES, page =>
              page !== 'Page 5-7' ? (
                <Page key={page}>
                  <Image src={`/${_.snakeCase(page)}_GIS.png`} />
                  {renderOverlay(page)}
                </Page>
              ) : (
                _.times(3, pageIndex => (
                  <Page key={pageIndex}>
                    <Image src={`/page_${_.toString(pageIndex + 5)}_GIS.png`} />
                    {renderOverlay(page, pageIndex)}
                  </Page>
                ))
              ),
            )}
          </Document>
        )}
      </PDFViewer>
    </div>
  );
};

PDFRender.defaultProps = {
  currentPage: '',
  src: '',
  all: false,
};

PDFRender.propTypes = {
  currentPage: PropTypes.string,
  src: PropTypes.string,
  initialData: PropTypes.shape().isRequired,
  page1: PropTypes.shape().isRequired,
  page2: PropTypes.shape().isRequired,
  page3: PropTypes.shape().isRequired,
  page4: PropTypes.shape().isRequired,
  page5to7: PropTypes.shape().isRequired,
  page8: PropTypes.shape().isRequired,
  page9: PropTypes.shape().isRequired,
  bod: PropTypes.shape().isRequired,
  all: PropTypes.bool,
};

const mapStateToProps = ({
  pages: { currentPage },
  initialData,
  fields: { page1, page2, page3, page4, page5to7, page8, page9, bod },
}) => ({
  currentPage,
  initialData,
  page1,
  page2,
  page3,
  page4,
  page5to7,
  page8,
  page9,
  bod,
});

const themedComponent = withTheme(PDFRender);

export default connect(mapStateToProps)(themedComponent);
