import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Checkbox } from '../../FormComponents';

const styles = makeStyles({
  input: {
    position: 'absolute',
    margin: 0,
  },
});

const Item1 = ({ fields, onChange }) => {
  const classes = styles();

  return (
    <>
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 262 }}
        checked={fields.banks}
        onChange={banks => onChange('banks', banks)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 287 }}
        checked={fields.offshoreBankingUnits}
        onChange={offshoreBankingUnits => onChange('offshoreBankingUnits', offshoreBankingUnits)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 312 }}
        checked={fields.quasiBanks}
        onChange={quasiBanks => onChange('quasiBanks', quasiBanks)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 339 }}
        checked={fields.trustEntities}
        onChange={trustEntities => onChange('trustEntities', trustEntities)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 367 }}
        checked={fields.nonStockSavings}
        onChange={nonStockSavings => onChange('nonStockSavings', nonStockSavings)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 392 }}
        checked={fields.pawnshops}
        onChange={pawnshops => onChange('pawnshops', pawnshops)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 419 }}
        checked={fields.foreignExchangeDealers}
        onChange={foreignExchangeDealers => onChange('foreignExchangeDealers', foreignExchangeDealers)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 444 }}
        checked={fields.moneyChangers}
        onChange={moneyChangers => onChange('moneyChangers', moneyChangers)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 472 }}
        checked={fields.remittanceChanges}
        onChange={remittanceChanges => onChange('remittanceChanges', remittanceChanges)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 499 }}
        checked={fields.electronicMoneyIssuers}
        onChange={electronicMoneyIssuers => onChange('electronicMoneyIssuers', electronicMoneyIssuers)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 524 }}
        checked={fields.financialInstitutions}
        onChange={financialInstitutions => onChange('financialInstitutions', financialInstitutions)}
      />
    </>
  );
};

Item1.defaultProps = {
  fields: {},
  onChange: () => {},
};

Item1.propTypes = {
  fields: PropTypes.shape(),
  onChange: PropTypes.func,
};

export default Item1;
