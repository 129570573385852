import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/styles';

import { PageEditTextField as TextField } from '../FormComponents';

const styles = () => ({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginBottom: 7,
  },
});

class Affiliates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.value.name,
      secRegistrationNumber: props.value.secRegistrationNumber,
      address: props.value.address,
    };
  }

  changeField = (field, value) =>
    this.setState({ [field]: value }, () => this.props.onChange({ [this.props.index]: this.state }));

  render = () => (
    <div className={this.props.classes.container}>
      <TextField
        placeholder="Subsidary/Affiliate"
        value={this.state.name}
        onChange={name => this.changeField('name', name)}
        variant="outlined"
        style={{ width: 374 }}
        optional
      />
      <TextField
        placeholder="SEC Registration Number"
        value={this.state.secRegistrationNumber}
        onChange={secRegistrationNumber => this.changeField('secRegistrationNumber', secRegistrationNumber)}
        variant="outlined"
        style={{ width: 219, left: 27 }}
        optional
      />
      <TextField
        placeholder="Address"
        value={this.state.address}
        onChange={address => this.changeField('address', address)}
        variant="outlined"
        style={{ width: 387, left: 47 }}
        optional
      />
    </div>
  );
}

Affiliates.defaultProps = {
  onChange: () => {},
  index: 0,
  value: {},
};

Affiliates.propTypes = {
  classes: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
  index: PropTypes.number,
  value: PropTypes.shape(),
};

export default withStyles(styles)(Affiliates);
