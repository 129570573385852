import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import { RadioSwitch, Checkbox, PageEditTextField as TextField } from '../FormComponents';
import { Item1, Item2, Item3, Item6, Item7 } from './Page2Checkboxes';

const imgSrc = require('../../images/Page_2_GIS.png');

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'fit-content',
    position: 'relative',
  },
  input: {
    position: 'absolute',
    margin: 0,
  },
});

const Page2 = ({ companyName, setValue, fields, displayError }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <img src={imgSrc} alt="Page 2 GIS" />
      <div className={`form-overlay ${classes.input}`}>
        <Typography className={classes.input} style={{ left: 234, top: 126, width: 800 }}>
          {companyName}
        </Typography>
      </div>
      <RadioSwitch
        className={classes.input}
        style={{ left: 900, top: 157 }}
        value={fields.amlaCoverage}
        onChange={amlaCoverage => setValue('amlaCoverage', amlaCoverage)}
      />
      <Item1 fields={fields.item1} onChange={(key, value) => setValue('item1', { ...fields.item1, [key]: value })} />
      <Item2 fields={fields.item2} onChange={(key, value) => setValue('item2', { ...fields.item2, [key]: value })} />
      <Item3 fields={fields.item3} onChange={(key, value) => setValue('item3', { ...fields.item3, [key]: value })} />
      <Checkbox
        className={classes.input}
        style={{ left: 702, top: 287 }}
        checked={fields.item4}
        onChange={item4 => setValue('item4', item4)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 702, top: 433 }}
        checked={fields.item5}
        onChange={item5 => setValue('item5', item5)}
      />
      <Item6 fields={fields.item6} onChange={(key, value) => setValue('item6', { ...fields.item6, [key]: value })} />
      <Item7 fields={fields.item7} onChange={(key, value) => setValue('item7', { ...fields.item7, [key]: value })} />
      <Checkbox
        className={classes.input}
        style={{ left: 702, top: 1328 }}
        checked={fields.item8}
        onChange={item8 => setValue('item8', item8)}
      />
      <TextField
        className={classes.input}
        style={{ width: 371, left: 796, top: 1367 }}
        placeholder="Nature of Business"
        value={fields.natureOfBusiness}
        onChange={natureOfBusiness => setValue('natureOfBusiness', natureOfBusiness)}
        variant="outlined"
        displayError={displayError}
      />
      <RadioSwitch
        className={classes.input}
        style={{ left: 900, top: 1475 }}
        value={fields.cddCompliance}
        onChange={cddCompliance => setValue('cddCompliance', cddCompliance)}
      />
    </div>
  );
};

Page2.defaultProps = {
  companyName: '',
  setValue: () => {},
  fields: {},
  displayError: false,
};

Page2.propTypes = {
  companyName: PropTypes.string,
  setValue: PropTypes.func,
  fields: PropTypes.shape(),
  displayError: PropTypes.bool,
};

const mapStateToProps = ({ initialData: { companyName, tin, registrationDate } }) => ({
  companyName,
  tin,
  registrationDate,
});

export default connect(mapStateToProps)(Page2);
