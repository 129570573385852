import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import SnackBar from '@material-ui/core/Snackbar';
import { withTheme, withStyles } from '@material-ui/styles';
import _ from 'lodash';

import EditableForm from '../components/EditableForm';
import PDFViewerModal from '../components/PDFViewerModal';
import Validate from '../components/Validate';
import { Select as CustomSelect, Checkbox } from '../components/FormComponents';
import { setFields, selectPage, logoutUser } from '../actions';
import { exclusiveOr } from '../services/helpers';

import {
  PAGES,
  PAGE_1_FIELDS_OMITS,
  PAGE_2_FIELDS_OMITS,
  PAGE_3_FIELDS_OMITS,
  PAGE_4_FIELDS_OMITS,
  PAGE_5_TO_7_FIELDS_OMITS,
  PAGE_8_FIELDS_OMITS,
  BOD_FIELDS_OMITS,
} from '../globals';

const Select = withStyles({
  root: {
    '&, &:focus': {
      backgroundColor: 'white',
      padding: '0 1rem',
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      width: '4rem',
      borderRadius: '4px',
    },
  },
})(CustomSelect);

const styles = () => ({
  header: {
    width: '100vw',
    height: '5rem',
  },
  headerItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: 'inherit',
    paddingLeft: '1rem',
    alignItems: 'center',
  },
  leftHeaderItem: {
    marginRight: '1rem',
  },
  rightHeader: {
    justifyContent: 'flex-end',
    paddingRight: '1rem',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100vw',
    padding: '1rem 6.2rem',
  },
  footerButtons: {
    marginLeft: '1rem',
    width: '6rem',
  },
});

class PageEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      saved: false,
      all: false,
      loading: false,
      presentEmptyFields: false,
      page1: props.fields.page1,
      page2: props.fields.page2,
      page3: props.fields.page3,
      page4: props.fields.page4,
      page5to7: props.fields.page5to7,
      page8: props.fields.page8,
      page9: props.fields.page9,
      bod: props.fields.bod,
    };
  }

  pageVariableName = () => (this.props.currentPage === 'Page 5-7' ? 'page5to7' : _.camelCase(this.props.currentPage));

  flagError = (hasEmpty, hasFilled) => !exclusiveOr(hasEmpty, hasFilled) && (hasEmpty || hasFilled);

  checkDependentFields = () => {
    let hasError = false;
    const fields = _.pick(this.state[this.pageVariableName()], [
      ...PAGE_3_FIELDS_OMITS,
      ...PAGE_4_FIELDS_OMITS,
      ...PAGE_5_TO_7_FIELDS_OMITS,
      ...PAGE_8_FIELDS_OMITS,
    ]);

    _.map(fields, (category, categoryKey) => {
      switch (categoryKey) {
        case 'authorizedCapitalStock':
        case 'officers':
        case 'dividendsDeclared':
        case 'additionalShares':
        case 'secondaryLicense': {
          _.map(category, items => {
            let hasEmptyField = false;
            let hasFilledField = false;

            _.map(items, (item, itemKey) => {
              if (_.includes(['total', 'board', 'execComm', 'officer'], itemKey)) return;
              if (_.isEmpty(item) || item === '' || _.isNil(item)) hasEmptyField = true;
              else hasFilledField = true;
            });
            if (this.flagError(hasEmptyField, hasFilledField)) hasError = true;
          });
          break;
        }
        case 'subscribedCapital':
        case 'paidupCapital': {
          _.map(category, subCategory => {
            _.map(subCategory, items => {
              let hasEmptyField = false;
              let hasFilledField = false;

              _.map(items, (item, key) => {
                if (_.includes(['numberOfSharesPublic', 'total', 'percentage'], key)) return;
                if (_.isEmpty(item) || item === '' || _.isNil(item)) hasEmptyField = true;
                else hasFilledField = true;
              });
              if (this.flagError(hasEmptyField, hasFilledField)) hasError = true;
            });
          });
          break;
        }
        case 'stockholders': {
          _.map(category, items => {
            let hasEmptyField = false;
            let hasFilledField = false;

            _.map(_.omit(items, ['nationality', 'address', 'tin', 'percentage']), (item, key) => {
              if (key === 'sharesSubscribed') {
                _.map(_.omit(item, ['numberTotal', 'amountTotal']), share => {
                  let shareHasEmpty = false;
                  let shareHasFilled = false;

                  _.map(share, shareItem => {
                    if (shareItem === '') shareHasEmpty = true;
                    else shareHasFilled = true;
                  });
                  if (this.flagError(shareHasEmpty, shareHasFilled)) hasError = true;
                });
              } else if (item === '') hasEmptyField = true;
              else hasFilledField = true;
            });
            if (this.flagError(hasEmptyField, hasFilledField)) hasError = true;
          });
          break;
        }
        default:
      }
    });

    return hasError;
  };

  checkEmptyFields = () => {
    let emptyFieldsPresent = false;
    const fields = _.omit(this.state[this.pageVariableName()], [
      ...PAGE_1_FIELDS_OMITS,
      ...PAGE_2_FIELDS_OMITS,
      ...PAGE_3_FIELDS_OMITS,
      ...PAGE_4_FIELDS_OMITS,
      ...PAGE_5_TO_7_FIELDS_OMITS,
      ...PAGE_8_FIELDS_OMITS,
      ...BOD_FIELDS_OMITS,
    ]);

    _.map(fields, field => {
      if ((_.isEmpty(field) || _.isNull(field)) && !_.isBoolean(field)) emptyFieldsPresent = true;
    });
    return emptyFieldsPresent;
  };

  saveChangesHandler = () =>
    this.setState({ loading: true }, async () => {
      if (this.checkEmptyFields() || this.checkDependentFields()) {
        this.setState({ open: false, presentEmptyFields: true, loading: false });
        await this.props.save(this.props.match.params.id, this.state, this.props.fields.new, this.props.fields.datumId);
        return;
      }
      await this.props.save(this.props.match.params.id, this.state, this.props.fields.new, this.props.fields.datumId);
      this.setState({ saved: true, presentEmptyFields: false, loading: false });
    });

  render = () => (
    <Validate>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <Grid container className={this.props.classes.header}>
            <Grid item lg={4} className={this.props.classes.leftHeader}>
              <Select
                value={this.props.currentPage}
                onChange={value => this.props.changePage(value)}
                variant="filled"
                className={this.props.classes.leftHeaderItem}
              >
                {_.map(PAGES, page => (
                  <MenuItem value={page} key={page}>
                    {page}
                  </MenuItem>
                ))}
              </Select>
              <Button
                style={{ marginRight: 16 }}
                variant="contained"
                color="primary"
                onClick={() => this.setState({ open: true })}
                className={this.props.classes.leftHeaderItem}
                disabled={this.state.loading}
              >
                Download Page
              </Button>
              <Button
                className={this.props.classes.leftHeaderItem}
                variant="contained"
                color="primary"
                onClick={() => this.setState({ open: true, all: true })}
                disabled={this.state.loading}
              >
                Download All
              </Button>
            </Grid>
            <Grid item lg={4} className={this.props.classes.headerItem}>
              <Typography variant="h4">{this.props.currentPage}</Typography>
            </Grid>
            <Grid item lg={4} className={`${this.props.classes.headerItem} ${this.props.classes.rightHeader}`}>
              <Button
                variant="contained"
                onClick={async () => {
                  await this.props.logout();
                  this.props.history.push('/');
                }}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {_.includes(['page3', 'page5to7'], this.pageVariableName()) && (
          <Grid item style={{ alignSelf: 'flex-start', paddingLeft: '1rem', marginBottom: '1rem' }}>
            <Checkbox
              label="Round Off Percentages"
              checked={
                !_.isNil(this.state[this.pageVariableName()].percentageRoundOff) &&
                this.state[this.pageVariableName()].percentageRoundOff
              }
              onChange={percentageRoundOff =>
                this.setState(prevState => ({
                  [this.pageVariableName()]: { ...prevState[this.pageVariableName()], percentageRoundOff },
                }))
              }
            />
          </Grid>
        )}
        <Grid item>
          <EditableForm
            currentPage={this.props.currentPage}
            setValue={(field, value) => {
              const page = this.pageVariableName();
              this.setState(prevState => ({
                [page]: {
                  ...prevState[page],
                  [field]: value,
                },
                saved: false,
              }));
            }}
            fields={this.state[this.pageVariableName()]}
            displayError={this.state.presentEmptyFields}
          />
        </Grid>
        <Grid item className={this.props.classes.footer}>
          <Button
            className={this.props.classes.footerButtons}
            variant="contained"
            onClick={() => this.props.history.push(`/${this.props.match.params.id}`)}
            disabled={this.state.loading}
          >
            Back
          </Button>
          <Button
            className={this.props.classes.footerButtons}
            variant="contained"
            color="primary"
            onClick={this.saveChangesHandler}
            disabled={this.state.loading}
          >
            Save
          </Button>
        </Grid>
        <PDFViewerModal
          currentPage={this.props.currentPage}
          src={`${_.snakeCase(this.props.currentPage)}_GIS.png`}
          open={this.state.open}
          onClose={() => this.setState({ open: false, all: false })}
          saved={this.state.saved}
          saveChanges={this.saveChangesHandler}
          all={this.state.all}
          loading={this.state.loading}
        />
      </Grid>
      {!_.isNull(this.props.fields.fieldsErrors) &&
        _.map(this.props.fields.fieldsErrors, error => <SnackBar open message={error} />)}
    </Validate>
  );
}

PageEdit.defaultProps = {
  currentPage: null,
};

PageEdit.propTypes = {
  currentPage: PropTypes.string,
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  save: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  fields: PropTypes.shape().isRequired,
  logout: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ pages: { currentPage }, fields }) => ({
  currentPage,
  fields,
});

const mapActionsToProps = {
  save: setFields,
  changePage: selectPage,
  logout: logoutUser,
};

const routedComponent = withRouter(PageEdit);
const themedComponent = withTheme(routedComponent);
const styledComponent = withStyles(styles)(themedComponent);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
