import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles(theme => ({
  inputContainerMargin: {
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    display: 'flex',
    flex: 1,
    flexFlow: 'column nowrap',
  },
  error: {
    color: '#ff0000',
  },
}));

const InputContainer = ({ error, children, noMargin }) => {
  const classes = styles();
  const className = `${classes.inputContainer} ${!noMargin && classes.inputContainerMargin} input-container`;

  return (
    <div className={className}>
      {children}
      {!_.isNull(error) ? (
        <Typography variant="caption" display="block" gutterBottom className={classes.error}>
          {error}
        </Typography>
      ) : null}
    </div>
  );
};

InputContainer.defaultProps = {
  error: null,
  noMargin: false,
};

InputContainer.propTypes = {
  error: PropTypes.string,
  children: PropTypes.node.isRequired,
  noMargin: PropTypes.bool,
};

export default InputContainer;
