import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MatCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/styles';

const StyledCheckbox = withStyles({
  root: {
    '&': {
      padding: 0,
    },
  },
})(MatCheckbox);

const Checkbox = ({ onChange, label, ...props }) => (
  <>
    <StyledCheckbox
      {...props}
      onChange={e => {
        e.persist();
        onChange(e.target.checked);
      }}
    />
    {!_.isNull(label) && <span style={{ marginLeft: '0.25rem' }}>{label}</span>}
  </>
);

Checkbox.defaultProps = {
  onChange: () => {},
  label: null,
};

Checkbox.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default Checkbox;
