import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import { Officers } from './Page4Components';
import { Grid, Paper, DataDisplay } from '../CustomComponents';
import { incompleteInputs } from '../../services/helpers';

const styles = {
  displayContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
};

class Page4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      officersCount: _.size(props.fields.officers),
    };
  }

  validOfficers = () => {
    let isValid = true;

    _.map(this.props.fields.officers, officer => {
      if (incompleteInputs(_.omit(officer, ['board']))) isValid = false;
    });

    return isValid;
  };

  render = () => (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <DataDisplay name="Corporate Name" value={this.props.companyName} />
        </Grid>
        <Grid item xs={12} style={{ alignItems: 'center' }} className={this.props.classes.displayContainer}>
          <Typography variant="button">Directors / Officers</Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: 0 }}>
          <Officers
            officers={this.props.fields.officers}
            officersCount={this.state.officersCount}
            setValue={(officer, index) =>
              this.props.setValue('officers', {
                ..._.omit(this.props.fields.officers, _.toString(index)),
                [index]: officer,
              })
            }
            displayError={this.props.displayError}
          />
        </Grid>
      </Grid>
      <Grid container noBorder>
        <Grid
          item
          noBorder
          className={this.props.classes.rowContainer}
          style={{ justifyContent: 'flex-end', width: '100%' }}
        >
          <Button
            variant="contained"
            style={this.state.officersCount > 0 ? { backgroundColor: '#e53935', color: 'white' } : null}
            onClick={() => {
              this.props.setValue(
                'officers',
                _.omit(this.props.fields.officers, _.toString(this.state.officersCount - 1)),
              );
              this.setState(({ officersCount }) => ({ officersCount: officersCount - 1 }));
            }}
            disabled={this.state.officersCount <= 0}
          >
            Delete Latest Entry
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            variant="contained"
            color="primary"
            onClick={() => {
              this.props.setValue('officers', {
                ...this.props.fields.officers,
                [this.state.officersCount]: {
                  name: '',
                  nationality: '',
                  incorporator: '',
                  board: '',
                  gender: '',
                  stockholder: '',
                  officer: '',
                  execComm: '',
                  tin: '',
                },
              });
              this.setState(({ officersCount }) => ({ officersCount: officersCount + 1 }));
            }}
            disabled={this.state.officersCount >= 15 || !this.validOfficers()}
          >
            Add Officer
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

Page4.defaultProps = {
  companyName: '',
  fields: {},
  setValue: () => {},
  displayError: false,
};

Page4.propTypes = {
  companyName: PropTypes.string,
  fields: PropTypes.shape(),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ initialData: { companyName } }) => ({
  companyName,
});

const styledComponent = withStyles(styles)(Page4);

export default connect(mapStateToProps)(styledComponent);
