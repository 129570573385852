import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import { Paper, Loading } from '../components/CustomComponents';
import { TextField, PasswordTextField } from '../components/FormComponents';
import { incompleteInputs } from '../services/helpers';
import { validateUser, logoutUser, loginUser } from '../actions';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
  paper: {
    width: 'calc(100vw/3)',
  },
  loadingPaper: {
    height: 'calc(100vh/3)',
  },
};

class LoginPage extends Component {
  state = {
    email: '',
    password: '',
    loading: false,
    pageLoading: false,
  };

  componentDidMount = () => {
    if (!_.isNil(localStorage.getItem('authToken'))) {
      this.setState({ pageLoading: true }, async () => {
        await this.props.validate();
        if (this.props.valid) this.props.history.push('/companies');
        else {
          await this.props.logout();
          this.setState({ pageLoading: false });
        }
      });
    }
  };

  render = () => (
    <Box className={this.props.classes.root}>
      {this.state.pageLoading ? (
        <Loading className={`${this.props.classes.paper} ${this.props.classes.loadingPaper}`} />
      ) : (
        <Paper className={this.props.classes.paper}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Typography variant="h4">Login</Typography>
            </Grid>
            <Grid item>
              <TextField value={this.state.email} placeholder="Email" onChange={email => this.setState({ email })} />
            </Grid>
            <Grid item>
              <PasswordTextField
                value={this.state.password}
                placeholder="Password"
                onChange={password => this.setState({ password })}
              />
            </Grid>
            <Grid item>
              <Button
                style={{ marginRight: 16 }}
                color="primary"
                disabled={incompleteInputs(this.state) || this.state.loading}
                variant="contained"
                onClick={() => {
                  this.setState({ loading: true }, async () => {
                    await this.props.login(this.state.email, this.state.password);
                    this.setState({ loading: false });
                    this.props.history.push('/companies');
                  });
                }}
              >
                Login
              </Button>
              <Button
                variant="contained"
                disabled={this.state.loading}
                onClick={() => this.props.history.push('/signup')}
              >
                Signup
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

LoginPage.defaultProps = {
  valid: null,
  validate: () => {},
  logout: () => {},
  login: () => {},
};

LoginPage.propTypes = {
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  valid: PropTypes.bool,
  validate: PropTypes.func,
  logout: PropTypes.func,
  login: PropTypes.func,
};

const mapStateToProps = ({ auth: { valid } }) => ({
  valid,
});
const mapActionsToProps = {
  validate: validateUser,
  logout: logoutUser,
  login: loginUser,
};

const styledComponent = withStyles(styles)(LoginPage);
const routedComponent = withRouter(styledComponent);

export default connect(mapStateToProps, mapActionsToProps)(routedComponent);
