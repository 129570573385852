import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';

import { Grid } from '../../CustomComponents';
import { PageEditTextField as TextField, Select } from '../../FormComponents';
import { validInputs } from '../../../services/helpers';

class Officer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.officer.name,
      nationality: this.props.officer.nationality,
      incorporator: this.props.officer.incorporator,
      board: this.props.officer.board,
      gender: this.props.officer.gender,
      stockholder: this.props.officer.stockholder,
      officer: this.props.officer.officer,
      execComm: this.props.officer.execComm,
      tin: this.props.officer.tin,
    };
  }

  changeField = (name, value) => this.setState({ [name]: value }, () => this.props.setValue(this.state));

  render = () => {
    const error = !validInputs(_.omit(this.state, ['board', 'officer'])) && this.props.displayError;

    return (
      <Grid container noBorder>
        <Grid item xs={2}>
          <TextField
            value={this.state.name}
            placeholder="Name"
            onChange={name => this.changeField('name', name)}
            variant="outlined"
            displayError={error}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            value={this.state.nationality}
            placeholder="Nationality"
            onChange={nationality => this.changeField('nationality', nationality)}
            variant="outlined"
            displayError={error}
          />
        </Grid>
        <Grid item xs={1}>
          <Select
            style={{ width: '100%' }}
            value={this.state.incorporator}
            onChange={incorporator => this.changeField('incorporator', incorporator)}
            variant="outlined"
            displayError={error}
          >
            <MenuItem value="Y">Y</MenuItem>
            <MenuItem value="N">N</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={1}>
          <Select
            style={{ width: '100%' }}
            value={this.state.board}
            onChange={board => this.changeField('board', board)}
            variant="outlined"
          >
            <MenuItem value="">_</MenuItem>
            <MenuItem value="C">C</MenuItem>
            <MenuItem value="M">M</MenuItem>
            <MenuItem value="I">I</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={1}>
          <Select
            style={{ width: '100%' }}
            value={this.state.gender}
            onChange={gender => this.changeField('gender', gender)}
            variant="outlined"
            displayError={error}
          >
            <MenuItem value="M">M</MenuItem>
            <MenuItem value="F">F</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={1}>
          <Select
            style={{ width: '100%' }}
            value={this.state.stockholder}
            onChange={stockholder => this.changeField('stockholder', stockholder)}
            variant="outlined"
            displayError={error}
          >
            <MenuItem value="Y">Y</MenuItem>
            <MenuItem value="N">N</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2}>
          <Select
            style={{ width: '100%' }}
            value={this.state.officer}
            onChange={officer => this.changeField('officer', officer)}
            variant="outlined"
          >
            <MenuItem value="President/ Chairman">President/Chairman</MenuItem>
            <MenuItem value="VP">VP</MenuItem>
            <MenuItem value="Treasurer">Treasurer</MenuItem>
            <MenuItem value="Secretary">Secretary</MenuItem>
            <MenuItem value="Director">Director</MenuItem>
            <MenuItem value="Compliance Officer">Compliance Officer</MenuItem>
            <MenuItem value="Member">Member</MenuItem>
            <MenuItem value="Associated Person">Associated Person</MenuItem>
            <MenuItem value="">_</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={1}>
          <Select
            style={{ width: '100%' }}
            value={this.state.execComm}
            onChange={execComm => this.changeField('execComm', execComm)}
            variant="outlined"
            displayError={error}
          >
            <MenuItem value="C/C">C/C</MenuItem>
            <MenuItem value="A/C">A/C</MenuItem>
            <MenuItem value="N/C">N/C</MenuItem>
            <MenuItem value="C/M">C/M</MenuItem>
            <MenuItem value="A/M">A/M</MenuItem>
            <MenuItem value="N/M">N/M</MenuItem>
            <MenuItem value="">_</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2}>
          <TextField
            value={this.state.tin}
            placeholder="TIN"
            onChange={tin => this.changeField('tin', tin)}
            variant="outlined"
            displayError={error}
          />
        </Grid>
      </Grid>
    );
  };
}

Officer.defaultProps = {
  officer: {
    name: '',
    nationality: '',
    incorporator: '',
    board: '',
    gender: '',
    stockholder: '',
    officer: '',
    execComm: '',
    tin: '',
  },
  setValue: () => {},
  displayError: false,
};

Officer.propTypes = {
  officer: PropTypes.shape({
    name: PropTypes.string,
    nationality: PropTypes.string,
    incorporator: PropTypes.string,
    board: PropTypes.string,
    gender: PropTypes.string,
    stockholder: PropTypes.string,
    officer: PropTypes.string,
    execComm: PropTypes.string,
    tin: PropTypes.string,
  }),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

export default Officer;
