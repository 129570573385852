const INITIAL_DATA_SUBMIT = 'initial_data_submit';
const INITIAL_DATA_CLEAR = 'initial_data_clear';

const PAGES_SELECT = 'pages_select';
const PAGES_CLEAR = 'pages_clear';

const FIELDS_SET = 'fields_set';
const FIELDS_CLEAR = 'fields_clear';
const FIELDS_FETCH_EMPTY = 'fields_fetch_empty';
const FIELDS_FETCH = 'fields_fetch';
const FIELDS_SET_ERROR = 'fields_set_error';

const AUTH_VALIDATE = 'auth_validate';
const AUTH_VALIDATE_CLEAR = 'auth_validate_clear';
const AUTH_LOGIN = 'auth_login';

export {
  INITIAL_DATA_SUBMIT,
  INITIAL_DATA_CLEAR,
  PAGES_SELECT,
  PAGES_CLEAR,
  FIELDS_SET,
  FIELDS_CLEAR,
  FIELDS_FETCH_EMPTY,
  FIELDS_FETCH,
  FIELDS_SET_ERROR,
  AUTH_VALIDATE,
  AUTH_VALIDATE_CLEAR,
  AUTH_LOGIN,
};
