import React from 'react';

import { Root } from './store';
import Routes from './config/routes';
import Theme from './config/theme';
import Picker from './config/picker';

const App = () => (
  <div id="app">
    <Root>
      <Theme>
        <Picker>
          <Routes />
        </Picker>
      </Theme>
    </Root>
  </div>
);

export default App;
