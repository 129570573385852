import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { withTheme, withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Validate from '../components/Validate';
import { TextField, DatePicker } from '../components/FormComponents';
import { Paper } from '../components/CustomComponents';

import { clearFields, submitData, logoutUser } from '../actions';
import axiosConfig from '../config/axios';
import { AUTH_API_ROOT } from '../globals';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
  paper: {
    width: 'calc(100vw/3)',
  },
};

class CompaniesCreate extends Component {
  state = {
    companyName: '',
    tin: '',
    registrationDate: moment(),
  };

  componentDidMount = () => this.props.clear();

  submitButtonHandler = async () => {
    try {
      const companiesResponse = await axiosConfig.post(`${AUTH_API_ROOT}/companies`, {
        company: {
          name: this.state.companyName,
          tin: this.state.tin,
          registration_date: moment(this.state.registrationDate).format('YYYY-MM-DD'),
        },
      });

      if (companiesResponse.status === 200) {
        try {
          const membershipResponse = await axiosConfig.post(
            `${AUTH_API_ROOT}/companies/${companiesResponse.data.id}/memberships`,
            {
              membership: {
                company_id: companiesResponse.data.id,
                user_id: this.props.userId,
              },
            },
          );

          if (membershipResponse.status === 200) {
            this.props.dataSubmit(this.state);
            this.props.history.push(`/${companiesResponse.data.id}`);
          }
        } catch (error) {
          Promise.reject(error);
        }
      }
    } catch (error) {
      Promise.reject(error);
    }
  };

  renderSubmitButton = () => {
    let disabled = false;

    _.map(this.state, (val, key) => {
      if ((key === 'registrationDate' && !moment(val).isValid()) || val.length < 1) disabled = true;
    });

    return (
      <Button
        style={{ marginRight: 16 }}
        variant="contained"
        color="primary"
        onClick={this.submitButtonHandler}
        disabled={disabled}
      >
        Submit and Edit
      </Button>
    );
  };

  render = () => (
    <Validate>
      <div className={this.props.classes.root}>
        <Box>
          <Paper className={this.props.classes.paper}>
            <Grid container spacing={3} direction="column">
              <Grid item>
                <Typography variant="h4">GIS Preparation</Typography>
              </Grid>
              <Grid item>
                <TextField
                  required
                  label="Company Name"
                  placeholder="Company Name"
                  value={this.state.companyName}
                  onChange={companyName => this.setState({ companyName })}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  label="TIN"
                  placeholder="TIN"
                  value={this.state.tin}
                  onChange={tin => this.setState({ tin })}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <DatePicker
                  label="Registration Date"
                  value={this.state.registrationDate}
                  onChange={date => this.setState({ registrationDate: date })}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item>
                {this.renderSubmitButton()}
                <Button
                  style={{ marginRight: 16 }}
                  variant="contained"
                  onClick={() => this.props.history.push('/companies')}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  onClick={async () => {
                    await this.props.logout();
                    this.props.history.push('/');
                  }}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
    </Validate>
  );
}

CompaniesCreate.defaultProps = {
  userId: null,
};

CompaniesCreate.propTypes = {
  clear: PropTypes.func.isRequired,
  dataSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  classes: PropTypes.shape().isRequired,
  logout: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

const mapStateToProps = ({ auth: { userId } }) => ({
  userId,
});
const mapActionsToProps = {
  clear: clearFields,
  dataSubmit: submitData,
  logout: logoutUser,
};

const themedComponent = withTheme(CompaniesCreate);
const styledComponent = withStyles(styles)(themedComponent);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
