import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PageSelection from '../pages/PageSelection';
import PageEdit from '../pages/PageEdit';
import SignupPage from '../pages/SignupPage';
import LoginPage from '../pages/LoginPage';
import Companies from '../pages/Companies';
import CompaniesCreate from '../pages/CompaniesCreate';

export default () => (
  <Router>
    <Switch>
      <Route path="/companies/create" component={CompaniesCreate} />
      <Route path="/companies" component={Companies} />
      <Route path="/signup" component={SignupPage} />
      <Route path="/:id/page-edit" component={PageEdit} />
      <Route path="/:id" component={PageSelection} />
      <Route path="/" component={LoginPage} />
    </Switch>
  </Router>
);
