import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Checkbox } from '../../FormComponents';

const styles = makeStyles({
  input: {
    position: 'absolute',
    margin: 0,
  },
});

const Item1 = ({ fields, onChange }) => {
  const classes = styles();

  return (
    <>
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 943 }}
        checked={fields.securitiesDealers}
        onChange={securitiesDealers => onChange('securitiesDealers', securitiesDealers)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 968 }}
        checked={fields.securitiesBrokers}
        onChange={securitiesBrokers => onChange('securitiesBrokers', securitiesBrokers)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 996 }}
        checked={fields.securitiesSalesman}
        onChange={securitiesSalesman => onChange('securitiesSalesman', securitiesSalesman)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 1042 }}
        checked={fields.investmentHouses}
        onChange={investmentHouses => onChange('investmentHouses', investmentHouses)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 1070 }}
        checked={fields.investmentAgentsAndConsultants}
        onChange={investmentAgentsAndConsultants =>
          onChange('investmentAgentsAndConsultants', investmentAgentsAndConsultants)
        }
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 1097 }}
        checked={fields.tradingAdvisors}
        onChange={tradingAdvisors => onChange('tradingAdvisors', tradingAdvisors)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 1122 }}
        checked={fields.otherEntitiesManagingSecurities}
        onChange={otherEntitiesManagingSecurities =>
          onChange('otherEntitiesManagingSecurities', otherEntitiesManagingSecurities)
        }
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 1168 }}
        checked={fields.mutualFunds}
        onChange={mutualFunds => onChange('mutualFunds', mutualFunds)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 1195 }}
        checked={fields.closeEndInvestmentCompanies}
        onChange={closeEndInvestmentCompanies => onChange('closeEndInvestmentCompanies', closeEndInvestmentCompanies)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 1222 }}
        checked={fields.commonTrustFunds}
        onChange={commonTrustFunds => onChange('commonTrustFunds', commonTrustFunds)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 1247 }}
        checked={fields.transferCompanies}
        onChange={transferCompanies => onChange('transferCompanies', transferCompanies)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 1275 }}
        checked={fields.otherEntitiesAdministeringCurrency}
        onChange={otherEntitiesAdministeringCurrency =>
          onChange('otherEntitiesAdministeringCurrency', otherEntitiesAdministeringCurrency)
        }
      />
      <Checkbox
        className={classes.input}
        style={{ left: 125, top: 1328 }}
        checked={fields.entitiesAdministeringValuableObjects}
        onChange={entitiesAdministeringValuableObjects =>
          onChange('entitiesAdministeringValuableObjects', entitiesAdministeringValuableObjects)
        }
      />
      <Checkbox
        className={classes.input}
        style={{ left: 125, top: 1353 }}
        checked={fields.entitiesAdministeringCashSubstitute}
        onChange={entitiesAdministeringCashSubstitute =>
          onChange('entitiesAdministeringCashSubstitute', entitiesAdministeringCashSubstitute)
        }
      />
    </>
  );
};

Item1.defaultProps = {
  fields: {},
  onChange: () => {},
};

Item1.propTypes = {
  fields: PropTypes.shape(),
  onChange: PropTypes.func,
};

export default Item1;
