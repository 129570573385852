import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import Paper from './Paper';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Loading = ({ className, text }) => {
  const classes = styles();

  return (
    <Paper className={`${classes.container} ${className}`}>
      {!_.isNull(text) && <Typography>{text}</Typography>}
      <CircularProgress />
    </Paper>
  );
};

Loading.defaultProps = {
  className: '',
  text: null,
};

Loading.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default Loading;
