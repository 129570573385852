import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { PageEditDatePicker as DatePicker, PageEditTextField as TextField } from '../FormComponents';
import MultipleFields from '../MultipleFields';
import { formatDate } from '../../services/helpers';

const imgSrc = require('../../images/Page_1_GIS.png');

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'fit-content',
    position: 'relative',
  },
  input: {
    position: 'absolute',
    margin: 0,
  },
});

const Page1 = ({ companyName, tin, registrationDate, setValue, fields, displayError }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <img src={imgSrc} alt="Page 1 GIS" />
      <div className={`form-overlay ${classes.input}`}>
        <Typography className={classes.input} style={{ left: 100, top: 596, width: 800 }}>
          {companyName}
        </Typography>
        <Typography className={classes.input} style={{ left: 936, top: 786, width: 197 }}>
          {tin}
        </Typography>
        <Typography className={classes.input} style={{ left: 936, top: 617, width: 197 }}>
          {formatDate(registrationDate)}
        </Typography>
      </div>
      <DatePicker
        className={classes.input}
        style={{ left: 600, top: 140, width: 127 }}
        views={['year']}
        value={fields.year}
        placeholder="YYYY"
        onChange={year => setValue('year', year)}
        format="YYYY"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 800, left: 100, top: 648 }}
        placeholder="Business Name"
        value={fields.businessName}
        onChange={businessName => setValue('businessName', businessName)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 800, left: 100, top: 708 }}
        placeholder="SEC Registration Number"
        value={fields.secRegistrationNumber}
        onChange={secRegistrationNumber => setValue('secRegistrationNumber', secRegistrationNumber)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ left: 100, top: 771, width: 800 }}
        value={fields.dateOfAnnualMeeting}
        placeholder="Date of Annual Meeting"
        variant="outlined"
        onChange={dateOfAnnualMeeting => setValue('dateOfAnnualMeeting', dateOfAnnualMeeting)}
        displayError={displayError}
      />
      <DatePicker
        className={classes.input}
        style={{ left: 100, top: 833, width: 300 }}
        value={fields.actualDateOfAnnualMeeting}
        placeholder="Actual Date of Annual Meeting"
        onChange={actualDateOfAnnualMeeting => setValue('actualDateOfAnnualMeeting', actualDateOfAnnualMeeting)}
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 800, left: 100, top: 887 }}
        placeholder="Complete Principal Office Address"
        value={fields.completePrincipalOfficeAddress}
        onChange={completePrincipalOfficeAddress =>
          setValue('completePrincipalOfficeAddress', completePrincipalOfficeAddress)
        }
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 800, left: 100, top: 947 }}
        placeholder="Complete Business Address"
        value={fields.completeBusinessAddress}
        onChange={completeBusinessAddress => setValue('completeBusinessAddress', completeBusinessAddress)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 400, left: 100, top: 1019 }}
        placeholder="Name of External Auditor"
        value={fields.nameOfExternalAuditor}
        onChange={nameOfExternalAuditor => setValue('nameOfExternalAuditor', nameOfExternalAuditor)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 300, left: 600, top: 1019 }}
        placeholder="SEC Accreditation Number"
        value={fields.secAccreditationNumber}
        onChange={secAccreditationNumber => setValue('secAccreditationNumber', secAccreditationNumber)}
        variant="outlined"
        displayError={displayError}
        optional
      />
      <TextField
        className={classes.input}
        style={{ width: 550, left: 100, top: 1092 }}
        placeholder="Primary Purpose"
        value={fields.primaryPurpose}
        onChange={primaryPurpose => setValue('primaryPurpose', primaryPurpose)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 249, left: 670, top: 1092 }}
        placeholder="Industry Classification"
        value={fields.industryClassification}
        onChange={industryClassification => setValue('industryClassification', industryClassification)}
        variant="outlined"
        displayError={displayError}
      />
      <DatePicker
        className={classes.input}
        style={{ left: 936, top: 684, width: 197 }}
        value={fields.fiscalYearEnd}
        placeholder="Fiscal Year End"
        onChange={fiscalYearEnd => setValue('fiscalYearEnd', fiscalYearEnd)}
        format="MMMM DD"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 218, left: 926, top: 834 }}
        placeholder="Website"
        value={fields.website}
        onChange={website => setValue('website', website)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 218, left: 926, top: 890 }}
        placeholder="Email"
        value={fields.email}
        onChange={email => setValue('email', email)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 218, left: 926, top: 947 }}
        placeholder="Fax Number"
        value={fields.faxNumber}
        onChange={faxNumber => setValue('faxNumber', faxNumber)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 218, left: 926, top: 1019 }}
        placeholder="Telephone Number"
        value={fields.telephoneNumber}
        onChange={telephoneNumber => setValue('telephoneNumber', telephoneNumber)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 218, left: 926, top: 1092 }}
        placeholder="Geographical Code"
        value={fields.geographicalCode}
        onChange={geographicalCode => setValue('geographicalCode', geographicalCode)}
        variant="outlined"
        displayError={displayError}
      />
      <TextField
        className={classes.input}
        style={{ width: 374, left: 100, top: 1187 }}
        placeholder="Parent Company"
        value={fields.parentCompany.name}
        onChange={name => setValue('parentCompany', { ...fields.parentCompany, name })}
        variant="outlined"
        optional
      />
      <TextField
        className={classes.input}
        style={{ width: 219, left: 500, top: 1187 }}
        placeholder="SEC Registration No."
        value={fields.parentCompany.secRegistrationNumber}
        onChange={secRegistrationNumber =>
          setValue('parentCompany', { ...fields.parentCompany, secRegistrationNumber })
        }
        variant="outlined"
        optional
      />
      <TextField
        className={classes.input}
        style={{ width: 387, left: 740, top: 1187 }}
        placeholder="Address"
        value={fields.parentCompany.address}
        onChange={address => setValue('parentCompany', { ...fields.parentCompany, address })}
        variant="outlined"
        optional
      />
      <MultipleFields
        className={classes.input}
        style={{ top: 1237, left: 100 }}
        type="affiliates"
        values={fields.affiliates}
        onChange={affiliate => setValue('affiliates', { ...fields.affiliates, ...affiliate })}
        count={5}
      />
    </div>
  );
};

Page1.defaultProps = {
  companyName: '',
  tin: '',
  registrationDate: '',
  setValue: () => {},
  fields: {},
  displayError: false,
};

Page1.propTypes = {
  companyName: PropTypes.string,
  tin: PropTypes.string,
  registrationDate: PropTypes.string,
  setValue: PropTypes.func,
  fields: PropTypes.shape(),
  displayError: PropTypes.bool,
};

const mapStateToProps = ({ initialData: { companyName, tin, registrationDate } }) => ({
  companyName,
  tin,
  registrationDate,
});

export default connect(mapStateToProps)(Page1);
