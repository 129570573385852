import _ from 'lodash';

import axiosConfig from '../config/axios';
import { FIELDS_SET, FIELDS_CLEAR, FIELDS_FETCH_EMPTY, FIELDS_FETCH, FIELDS_SET_ERROR } from './types';
import { AUTH_API_ROOT } from '../globals';

const setFields = (companyId, states, newForm, datumId) => async dispatch => {
  const datum = {
    pageOne: states.page1,
    pageTwo: states.page2,
    pageThree: {
      ...states.page3,
      percentageRoundOff: !_.isNil(states.page3.percentageRoundOff) && states.page3.percentageRoundOff,
    },
    pageFour: states.page4,
    pageFiveToSeven: {
      ...states.page5to7,
      percentageRoundOff: !_.isNil(states.page5to7.percentageRoundOff) && states.page5to7.percentageRoundOff,
    },
    pageEight: states.page8,
    pageNine: states.page9,
    bod: states.bod,
  };

  try {
    const { data, status } = newForm
      ? await axiosConfig.post(`${AUTH_API_ROOT}/companies/${companyId}/data`, { datum })
      : await axiosConfig.patch(`${AUTH_API_ROOT}/companies/${companyId}/data/${datumId}`, { datum });

    if (status === 200) {
      dispatch({
        type: FIELDS_SET,
        payload: {
          datumId,
          new: false,
          page1: data.pageOne,
          page2: data.pageTwo,
          page3: data.pageThree,
          page4: data.pageFour,
          page5to7: data.pageFiveToSeven,
          page8: data.pageEight,
          page9: data.pageNine,
          bod: data.bod,
        },
      });
    }
  } catch (error) {
    dispatch({ type: FIELDS_SET_ERROR, payload: error.response.data.errors });
    setTimeout(async () => dispatch({ type: FIELDS_SET_ERROR, payload: null }), 3000);
  }
};

const clearFields = () => ({ type: FIELDS_CLEAR });

const fetchFields = companiesId => async dispatch => {
  try {
    const { data, status } = await axiosConfig.get(`${AUTH_API_ROOT}/companies/${companiesId}/data`);

    if (status === 200) {
      if (_.isEmpty(data)) {
        dispatch({ type: FIELDS_FETCH_EMPTY });
      } else {
        const fieldsData = _.first(data);

        dispatch({
          type: FIELDS_FETCH,
          payload: {
            page1: fieldsData.pageOne,
            page2: fieldsData.pageTwo,
            page3: fieldsData.pageThree,
            page4: fieldsData.pageFour,
            page5to7: fieldsData.pageFiveToSeven,
            page8: fieldsData.pageEight,
            page9: fieldsData.pageNine,
            bod: fieldsData.bod,
            datumId: fieldsData.id,
          },
        });
      }
    }
  } catch (error) {
    Promise.reject(error);
  }
};

export { setFields, clearFields, fetchFields };
