import _ from 'lodash';
import moment from 'moment';

import { DATE_FORMAT } from '../globals';

const formatDate = date => moment(date).format(DATE_FORMAT);

const formatYear = date => moment(date).format('YYYY');

const formatMonthDay = date => moment(date).format('MMMM DD');

const hasFalsey = array => {
  let isFalsey = false;

  _.map(array, value => {
    if (_.isUndefined(value) || _.isNull(value)) isFalsey = true;
  });

  return isFalsey;
};

const hasInput = value => !_.isUndefined(value) && !_.isEmpty(value);

const convertToNumber = number => parseFloat(_.replace(number, /,/g, ''));

const allEmptyInputs = array => {
  let hasEmpty = false;
  let hasFilled = false;

  _.map(array, item => {
    if (item === '') hasEmpty = true;
    else hasFilled = true;
  });

  if (hasEmpty && !hasFilled) return true;
  return false;
};

const exclusiveOr = (a, b) => (a && !b) || (!a && b);

const validInputs = collection => {
  let hasEmpty = false;
  let hasFilled = false;

  _.map(collection, item => {
    if (item === '') hasEmpty = true;
    else hasFilled = true;
  });

  if (!exclusiveOr(hasEmpty, hasFilled) && (hasEmpty || hasFilled)) return false;
  return true;
};

const incompleteInputs = collection => {
  let hasEmpty = false;

  _.map(collection, item => {
    if (item === '') hasEmpty = true;
  });

  return hasEmpty;
};

const isEmptyInput = input => _.isNil(input) || input === '';

export {
  formatDate,
  formatYear,
  formatMonthDay,
  hasFalsey,
  hasInput,
  convertToNumber,
  allEmptyInputs,
  exclusiveOr,
  validInputs,
  incompleteInputs,
  isEmptyInput,
};
