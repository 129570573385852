import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
});

const DataDisplay = ({ name, value }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Typography variant="button" style={{ marginRight: 12 }}>
        {`${name}:`}
      </Typography>
      <Typography>{value}</Typography>
    </div>
  );
};

DataDisplay.defaultProps = {
  name: '',
  value: '',
};

DataDisplay.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DataDisplay;
