import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles({
  radioGroup: {
    flexFlow: 'row nowrap',
  },
});

const RadioSwitch = ({ value, onChange, ...props }) => {
  const classes = styles();

  return (
    <FormControl {...props}>
      <RadioGroup
        value={value ? 'yes' : 'no'}
        className={classes.radioGroup}
        onChange={e => {
          e.persist();
          onChange(e.target.value === 'yes');
        }}
      >
        <FormControlLabel control={<Radio />} value="yes" label="Yes" />
        <FormControlLabel control={<Radio />} value="no" label="No" />
      </RadioGroup>
    </FormControl>
  );
};

RadioSwitch.defaultProps = {
  value: true,
  onChange: () => {},
};

RadioSwitch.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RadioSwitch;
