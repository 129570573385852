import React from 'react';
import MatPaper from '@material-ui/core/Paper';

const styles = {
  paper: {
    padding: '1rem',
  },
};

const Paper = props => <MatPaper {...props} style={styles.paper} />;

export default Paper;
