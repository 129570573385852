import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { View, StyleSheet } from '@react-pdf/renderer';

import Text from './Text';
import { numberFormat as customNumberFormat } from '../CustomComponents';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    fontSize: 7,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  columnContainer: {
    flexDirection: 'column',
  },
});

const Page5to7Overlay = ({ fields }) => {
  const numberFormat = number => customNumberFormat(number, true);

  const getTotal = amounts => !_.isEmpty(amounts) && numberFormat(_.sum(amounts));

  return (
    <>
      <Text style={[styles.overlay, { left: 110, top: 88, width: 462, height: 21, fontSize: 8 }]} bold>
        {fields.companyName}
      </Text>
      <Text style={[styles.overlay, { left: 160, top: 109, height: 18, width: 127, fontSize: 8 }]} bold>
        {fields.totalNumberOfStockholders}
      </Text>
      <Text style={[styles.overlay, { left: 485, top: 109, height: 18, width: 85, fontSize: 8 }]} bold>
        {fields.numberOfStockholdersShares}
      </Text>
      <Text style={[styles.overlay, { left: 270, top: 128, height: 22, width: 300, fontSize: 8 }]} bold>
        {fields.totalAssets}
      </Text>
      <View style={[styles.overlay, { left: 50, top: 222 }]}>
        {_.map(fields.stockholders, stockholder => (
          <View key={stockholder.name} style={[styles.rowContainer, { height: 60.25 }]}>
            <View style={[styles.columnContainer, { width: 141, paddingBottom: 3, alignItems: 'flex-start' }]}>
              <Text>{stockholder.name}</Text>
              <Text>{stockholder.nationality}</Text>
              <Text>{stockholder.address}</Text>
            </View>
            <View style={[styles.columnContainer]}>
              <View style={{ height: 45 }}>
                {_.map(_.omit(stockholder.sharesSubscribed, ['numberTotal', 'amountTotal']), share => (
                  <View key={share.type} style={[styles.rowContainer, { height: 14.5 }]}>
                    <Text style={{ width: 40 }}>{share.type}</Text>
                    <Text style={{ width: 56, paddingRight: 1 }}>{numberFormat(share.number)}</Text>
                    <Text style={{ width: 68, paddingRight: 1 }}>{numberFormat(share.amount)}</Text>
                  </View>
                ))}
              </View>
              <View style={[styles.rowContainer, { height: 15 }]}>
                <Text style={{ left: 41, width: 56, paddingRight: 2 }}>
                  {numberFormat(stockholder.sharesSubscribed.numberTotal)}
                </Text>
                <Text style={{ left: 41, width: 68, paddingRight: 2 }}>
                  {numberFormat(stockholder.sharesSubscribed.amountTotal)}
                </Text>
              </View>
            </View>
            <Text style={{ width: 40 }}>
              {numberFormat(
                !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
                  ? Math.round(stockholder.percentage)
                  : stockholder.percentage,
              )}
              %
            </Text>
            <Text style={{ width: 94 }}>{numberFormat(stockholder.sharesSubscribed.amountTotal)}</Text>
            <Text style={{ width: 82 }}>{stockholder.tin}</Text>
          </View>
        ))}
      </View>
      <Text style={[styles.overlay, { left: 356, top: 642, height: 16, width: 40 }]} bold>
        {getTotal(_.compact(_.map(fields.stockholders, 'percentage')))}%
      </Text>
      <Text style={[styles.overlay, { left: 396, top: 642, height: 32, width: 176 }]} bold>
        {getTotal(_.compact(_.map(fields.stockholders, stockholder => stockholder.sharesSubscribed.amountTotal)))}
      </Text>
    </>
  );
};

Page5to7Overlay.defaultProps = {
  fields: {},
};

Page5to7Overlay.propTypes = {
  fields: PropTypes.shape(),
};

export default Page5to7Overlay;
