import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { View, StyleSheet } from '@react-pdf/renderer';

import Text from './Text';
import { numberFormat as customNumberFormat } from '../CustomComponents';
import { formatDate, isEmptyInput } from '../../services/helpers';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    fontSize: 8,
  },
  rowContainer: {
    flexDirection: 'row',
  },
  columnContainer: {
    flexDirection: 'column',
  },
});

const Page8Overlay = ({ fields }) => {
  const numberFormat = number => customNumberFormat(number, true);

  const dateFormat = date => (!_.isNil(date) ? formatDate(date) : null);

  const secondaryLicenseWidth = key => {
    switch (key) {
      case 'sec':
        return 136;
      case 'bsp':
        return 141;
      case 'ic':
        return 147;
      default:
        return 0;
    }
  };

  return (
    <>
      <Text style={[styles.overlay, { top: 104, left: 125, width: 445, alignItems: 'flex-start' }]} bold>
        {fields.companyName}
      </Text>
      <View style={[styles.overlay, { top: 155, left: 243 }]}>
        {_.map(fields.investmentInAnotherCorporation, (investment, key) => (
          <View key={key} style={[styles.rowContainer, { marginBottom: 1, height: 20 }]}>
            <Text style={{ width: 180 }}>
              {isEmptyInput(investment.amount) ? 'N/A' : numberFormat(investment.amount)}
            </Text>
            <Text style={{ width: 146 }}>{dateFormat(investment.date)}</Text>
          </View>
        ))}
      </View>
      <View style={[styles.overlay, styles.rowContainer, { top: 292, left: 44, height: 27 }]}>
        <Text style={{ width: 301 }}>
          {isEmptyInput(fields.investmentInSecondaryPurposes.name) ? 'N/A' : fields.investmentInSecondaryPurposes.name}
        </Text>
        <Text style={{ width: 120 }}>
          {isEmptyInput(fields.investmentInSecondaryPurposes.boardResolutionDate)
            ? 'N/A'
            : dateFormat(fields.investmentInSecondaryPurposes.boardResolutionDate)}
        </Text>
        <Text style={{ width: 104 }}>
          {isEmptyInput(fields.investmentInSecondaryPurposes.stockholderRatificationDate)
            ? 'N/A'
            : dateFormat(fields.investmentInSecondaryPurposes.stockholderRatificationDate)}
        </Text>
      </View>
      <View style={[styles.overlay, styles.rowContainer, { top: 330, left: 44, height: 43 }]}>
        <Text style={{ width: 301 }}>{fields.treasuryShares.name}</Text>
        <Text style={{ width: 120, height: 21, marginTop: 22 }}>
          {isEmptyInput(fields.treasuryShares.numberOfShares)
            ? 'N/A'
            : numberFormat(fields.treasuryShares.numberOfShares)}
        </Text>
        <Text style={{ width: 104, height: 21, marginTop: 22 }}>{numberFormat(fields.treasuryShares.percentage)}</Text>
      </View>
      <Text
        style={[
          styles.overlay,
          { top: 374, left: 410, width: 160, height: 12, alignItems: 'flex-end', paddingRight: 12 },
        ]}
      >
        {numberFormat(fields.unrestrictedRetainedEarnings)}
      </Text>
      <View style={[styles.overlay, { top: 420, left: 281 }]}>
        {_.map(fields.dividendsDeclared, (dividends, key) => (
          <View key={key} style={[styles.rowContainer, { height: 12, marginBottom: 1 }]}>
            <Text style={{ width: 184 }}>
              {isEmptyInput(dividends.amount) ? 'N/A' : numberFormat(dividends.amount)}
            </Text>
            <Text style={{ width: 104 }}>{dateFormat(dividends.date)}</Text>
          </View>
        ))}
      </View>
      <View style={[styles.overlay, { top: 497, left: 44 }]}>
        {_.map(fields.additionalShares, (share, key) => (
          <View key={key} style={[styles.rowContainer, { height: 11 }]}>
            <Text style={{ width: 101 }}>{dateFormat(share.date)}</Text>
            <Text style={{ width: 200 }}>{share.numberOfShares}</Text>
            <Text style={{ width: 224 }}>{numberFormat(share.amount)}</Text>
          </View>
        ))}
      </View>
      <View style={[styles.overlay, styles.rowContainer, { top: 565, left: 145 }]}>
        {_.map(
          {
            sec: fields.secondaryLicense.sec,
            bsp: fields.secondaryLicense.bsp,
            ic: fields.secondaryLicense.ic,
          },
          (license, key) => (
            <View key={key} style={[styles.columnContainer, { width: secondaryLicenseWidth(key) }]}>
              <Text style={{ height: 22 }}>{isEmptyInput(license.type) ? 'N/A' : license.type}</Text>
              <Text style={{ height: 22 }}>
                {isEmptyInput(license.dateIssued) ? 'N/A' : dateFormat(license.dateIssued)}
              </Text>
              <Text style={{ height: 23 }}>
                {isEmptyInput(license.dateStarted) ? 'N/A' : dateFormat(license.dateStarted)}
              </Text>
            </View>
          ),
        )}
      </View>
      <Text style={[styles.overlay, { top: 670, left: 44, width: 199, height: 23 }]}>
        {isEmptyInput(fields.totalAnnualCompensation) ? 'N/A' : numberFormat(fields.totalAnnualCompensation)}
      </Text>
      <Text style={[styles.overlay, { top: 670, left: 243, width: 118, height: 23 }]}>
        {isEmptyInput(fields.totalNoOfficers) ? 'N/A' : fields.totalNoOfficers}
      </Text>
      <Text style={[styles.overlay, { top: 670, left: 361, width: 104, height: 23 }]}>
        {isEmptyInput(fields.totalNoRank) ? 'N/A' : fields.totalNoRank}
      </Text>
      <Text style={[styles.overlay, { top: 670, left: 465, width: 104, height: 23 }]}>
        {isEmptyInput(fields.totalManpower) ? 'N/A' : fields.totalManpower}
      </Text>
    </>
  );
};

Page8Overlay.defaultProps = {
  fields: {},
};

Page8Overlay.propTypes = {
  fields: PropTypes.shape(),
};

export default Page8Overlay;
