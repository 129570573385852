import { FIELDS_SET, FIELDS_CLEAR, FIELDS_FETCH_EMPTY, FIELDS_FETCH, FIELDS_SET_ERROR } from '../actions/types';

import {
  PAGE_1_FIELDS,
  PAGE_2_FIELDS,
  PAGE_3_FIELDS,
  PAGE_4_FIELDS,
  PAGE_5_TO_7_FIELDS,
  PAGE_8_FIELDS,
  PAGE_9_FIELDS,
  BOD_FIELDS,
} from '../defaultState';

const DEFAULT_STATE = {
  datumId: null,
  new: false,
  page1: PAGE_1_FIELDS,
  page2: PAGE_2_FIELDS,
  page3: PAGE_3_FIELDS,
  page4: PAGE_4_FIELDS,
  page5to7: PAGE_5_TO_7_FIELDS,
  page8: PAGE_8_FIELDS,
  page9: PAGE_9_FIELDS,
  bod: BOD_FIELDS,
  fieldsErrors: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FIELDS_SET:
      return { ...state, ...action.payload };
    case FIELDS_CLEAR:
      return DEFAULT_STATE;
    case FIELDS_FETCH_EMPTY:
      return { ...state, new: true };
    case FIELDS_FETCH:
      return { ...state, ...action.payload, new: false };
    case FIELDS_SET_ERROR:
      return { ...state, fieldsErrors: action.payload };
    default:
      return state;
  }
};
