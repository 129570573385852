export default {
  amlaCoverage: false,
  item1: {
    banks: false,
    offshoreBankingUnits: false,
    quasiBanks: false,
    trustEntities: false,
    nonStockSavings: false,
    pawnshops: false,
    foreignExchangeDealers: false,
    moneyChangers: false,
    remittanceAgents: false,
    electronicMoneyIssuers: false,
    financialInstitutions: false,
  },
  item2: {
    insuranceCompanies: false,
    insuranceAgents: false,
    insuranceBrokers: false,
    professionalReinsurers: false,
    reinsuranceBrokers: false,
    holdingCompanies: false,
    holdingCompanySystems: false,
    preNeedCompanies: false,
    mutualBenefitAssociation: false,
    otherPersons: false,
  },
  item3: {
    securitiesDealers: false,
    securitiesBrokers: false,
    securitiesSalesman: false,
    investmentHouses: false,
    investmentAgentsAndConsultants: false,
    tradingAdvisors: false,
    otherEntitiesManagingSecurities: false,
    mutualFunds: false,
    closeEndInvestmentCompanies: false,
    commonTrustFunds: false,
    transferCompanies: false,
    otherEntitiesAdministeringCurrency: false,
    entitiesAdministeringValuableObjects: false,
    entitiesAdministeringCashSubstitute: false,
  },
  item4: false,
  item5: false,
  item6: {
    actingFormationAgent: false,
    actingDirector: false,
    providingRegisteredOffice: false,
    actingNominee: false,
  },
  item7: {
    clientMoneyManagement: false,
    bankManagement: false,
    contributionsOrganization: false,
    juridicalPersonManagement: false,
  },
  item8: false,
  natureOfBusiness: '',
  cddCompliance: false,
};
