import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { View, StyleSheet } from '@react-pdf/renderer';

import Text from './Text';
import { numberFormat } from '../CustomComponents';
import { convertToNumber } from '../../services/helpers';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    fontSize: 8,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  colContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const Page3Overlay = ({ fields }) => {
  const totalCapital = type => {
    const capitalTotals = fields.totals[type];

    return capitalTotals.filipino || capitalTotals.foreign
      ? _.sum(_.compact([capitalTotals.filipino, capitalTotals.foreign]))
      : null;
  };

  return (
    <>
      <Text style={[styles.overlay, { left: 115, top: 73, height: 21, width: 430 }]} bold>
        {fields.companyName}
      </Text>
      <View style={[styles.overlay, styles.colContainer, { left: 137, top: 150, height: 46 }]}>
        {_.map(fields.authorizedCapitalStock, (capital, key) => (
          <View key={key} style={[styles.rowContainer, { marginBottom: 2, height: 12 }]}>
            <Text style={{ width: 84 }}>{capital.typeOfShare}</Text>
            <Text style={{ width: 59 }}>{capital.numberOfShares}</Text>
            <Text style={{ width: 113 }}>{numberFormat(capital.parStatedValue, true)}</Text>
            <Text style={{ width: 152 }}>{numberFormat(capital.total, true)}</Text>
          </View>
        ))}
      </View>
      <Text style={[styles.overlay, { left: 221, top: 196, width: 59, height: 12 }]}>
        {!_.isEmpty(fields.authorizedCapitalStock) &&
          _.sum(_.map(fields.authorizedCapitalStock, ({ numberOfShares }) => convertToNumber(numberOfShares)))}
      </Text>
      <Text style={[styles.overlay, { left: 393, top: 196, width: 152, height: 12 }]}>
        {numberFormat(fields.totals.authorizedCapitalStock, true)}
      </Text>
      <View style={[styles.overlay, styles.colContainer, { left: 93, top: 273, height: 46 }]}>
        {_.map(fields.subscribedCapital.filipino, (capital, key) => (
          <View key={key} style={[styles.rowContainer, { marginBottom: 2, height: 12 }]}>
            <Text style={{ width: 44 }}>{capital.numberOfStockholders}</Text>
            <Text style={{ width: 85 }}>{capital.typeOfShare}</Text>
            <Text style={{ width: 58 }}>{capital.numberOfShares}</Text>
            <Text style={{ width: 56 }}>{capital.numberOfSharesPublic}</Text>
            <Text style={{ width: 57 }}>{numberFormat(capital.parStatedValue, true)}</Text>
            <Text style={{ width: 100, alignItems: 'flex-end', paddingRight: 5 }}>
              {numberFormat(capital.total, true)}
            </Text>
            <Text style={{ width: 52 }}>
              {numberFormat(
                !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
                  ? Math.round(capital.percentage)
                  : capital.percentage,
                true,
              )}
              %
            </Text>
          </View>
        ))}
      </View>
      <Text style={[styles.overlay, { left: 221, top: 319, height: 12, width: 59 }]}>
        {!_.isEmpty(fields.subscribedCapital.filipino) &&
          _.sum(_.map(fields.subscribedCapital.filipino, ({ numberOfShares }) => convertToNumber(numberOfShares)))}
      </Text>
      <Text
        style={[
          styles.overlay,
          { left: 393, top: 319, height: 12, width: 100, alignItems: 'flex-end', paddingRight: 5 },
        ]}
      >
        {numberFormat(fields.totals.subscribedCapital.filipino, true)}
      </Text>
      <Text style={[styles.overlay, { left: 493, top: 319, width: 52, height: 12 }]}>
        {numberFormat(
          !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
            ? Math.round(fields.percentages.subscribedCapital.filipino)
            : fields.percentages.subscribedCapital.filipino,
          true,
        )}
        %
      </Text>
      <View style={[styles.overlay, styles.colContainer, { left: 34, top: 382, height: 57 }]}>
        {_.map(fields.subscribedCapital.foreign, (capital, key) => (
          <View key={key} style={[styles.rowContainer, { marginBottom: 2, height: 12 }]}>
            <Text style={{ width: 59 }}>{capital.nationality}</Text>
            <Text style={{ width: 44 }}>{capital.numberOfStockholders}</Text>
            <Text style={{ width: 85 }}>{capital.typeOfShare}</Text>
            <Text style={{ width: 58 }}>{capital.numberOfShares}</Text>
            <Text style={{ width: 56 }}>{capital.numberOfSharesPublic}</Text>
            <Text style={{ width: 57 }}>{numberFormat(capital.parStatedValue, true)}</Text>
            <Text style={{ width: 100, alignItems: 'flex-end', paddingRight: 5 }}>
              {numberFormat(capital.total, true)}
            </Text>
            <Text style={{ width: 52 }}>
              {numberFormat(
                !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
                  ? Math.round(capital.percentage)
                  : capital.percentage,
                true,
              )}
              %
            </Text>
          </View>
        ))}
      </View>
      <Text style={[styles.overlay, { left: 221, top: 439, width: 59, height: 12 }]}>
        {!_.isEmpty(fields.subscribedCapital.foreign) &&
          _.sum(_.map(fields.subscribedCapital.foreign, ({ numberOfShares }) => convertToNumber(numberOfShares)))}
      </Text>
      <Text
        style={[
          styles.overlay,
          { left: 393, top: 439, height: 12, width: 100, alignItems: 'flex-end', paddingRight: 5 },
        ]}
      >
        {numberFormat(fields.totals.subscribedCapital.foreign, true)}
      </Text>
      <Text style={[styles.overlay, { left: 493, top: 439, width: 52, height: 12 }]}>
        {numberFormat(
          !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
            ? Math.round(fields.percentages.subscribedCapital.foreign)
            : fields.percentages.subscribedCapital.foreign,
          true,
        )}
        %
      </Text>
      <Text
        style={[
          styles.overlay,
          { left: 393, top: 450, width: 100, height: 12, alignItems: 'flex-end', paddingRight: 5 },
        ]}
      >
        {numberFormat(totalCapital('subscribedCapital'), true)}
      </Text>
      <Text style={[styles.overlay, { left: 493, top: 450, width: 52, height: 12 }]}>
        {!_.isNull(totalCapital('subscribedCapital')) && numberFormat(100, true)}%
      </Text>
      <View style={[styles.overlay, styles.colContainer, { left: 93, top: 509, height: 57 }]}>
        {_.map(fields.paidupCapital.filipino, (capital, key) => (
          <View key={key} style={[styles.rowContainer, { marginBottom: 2 }]}>
            <Text style={{ width: 44 }}>{capital.numberOfStockholders}</Text>
            <Text style={{ width: 85 }}>{capital.typeOfShare}</Text>
            <Text style={{ width: 58 }}>{capital.numberOfShares}</Text>
            <Text style={{ width: 113 }}>{numberFormat(capital.parStatedValue, true)}</Text>
            <Text style={{ width: 100, alignItems: 'flex-end', paddingRight: 5 }}>
              {numberFormat(capital.total, true)}
            </Text>
            <Text style={{ width: 52 }}>
              {numberFormat(
                !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
                  ? Math.round(capital.percentage)
                  : capital.percentage,
                true,
              )}
              %
            </Text>
          </View>
        ))}
      </View>
      <Text style={[styles.overlay, { left: 221, top: 566, width: 59, height: 12 }]}>
        {!_.isEmpty(fields.paidupCapital.filipino) &&
          _.sum(_.map(fields.paidupCapital.filipino, ({ numberOfShares }) => convertToNumber(numberOfShares)))}
      </Text>
      <Text
        style={[
          styles.overlay,
          { left: 393, top: 566, height: 12, width: 100, alignItems: 'flex-end', paddingRight: 5 },
        ]}
      >
        {numberFormat(fields.totals.paidupCapital.filipino, true)}
      </Text>
      <Text style={[styles.overlay, { left: 493, top: 566, width: 52, height: 12 }]}>
        {numberFormat(
          !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
            ? Math.round(fields.percentages.paidupCapital.filipino)
            : fields.percentages.paidupCapital.filipino,
          true,
        )}
        %
      </Text>
      <View style={[styles.overlay, styles.colContainer, { left: 34, top: 605, height: 58 }]}>
        {_.map(fields.paidupCapital.foreign, (capital, key) => (
          <View key={key} style={[styles.rowContainer, { marginBottom: 2 }]}>
            <Text style={{ width: 59 }}>{capital.nationality}</Text>
            <Text style={{ width: 44 }}>{capital.numberOfStockholders}</Text>
            <Text style={{ width: 85 }}>{capital.typeOfShare}</Text>
            <Text style={{ width: 58 }}>{capital.numberOfShares}</Text>
            <Text style={{ width: 113 }}>{numberFormat(capital.parStatedValue, true)}</Text>
            <Text style={{ width: 100, alignItems: 'flex-end', paddingRight: 5 }}>
              {numberFormat(capital.total, true)}
            </Text>
            <Text style={{ width: 52 }}>
              {numberFormat(
                !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
                  ? Math.round(capital.percentage)
                  : capital.percentage,
                true,
              )}
              %
            </Text>
          </View>
        ))}
      </View>
      <Text style={[styles.overlay, { left: 221, top: 663, width: 58, height: 12 }]}>
        {!_.isEmpty(fields.paidupCapital.foreign) &&
          _.sum(_.map(fields.paidupCapital.foreign, ({ numberOfShares }) => convertToNumber(numberOfShares)))}
      </Text>
      <Text
        style={[
          styles.overlay,
          { left: 393, top: 663, width: 100, height: 12, alignItems: 'flex-end', paddingRight: 5 },
        ]}
      >
        {numberFormat(fields.totals.paidupCapital.foreign, true)}
      </Text>
      <Text style={[styles.overlay, { left: 493, top: 663, width: 52, height: 12 }]}>
        {numberFormat(
          !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
            ? Math.round(fields.percentages.paidupCapital.foreign)
            : fields.percentages.paidupCapital.foreign,
          true,
        )}
        %
      </Text>
      <Text
        style={[
          styles.overlay,
          { left: 393, top: 675, width: 100, height: 12, alignItems: 'flex-end', paddingRight: 5 },
        ]}
      >
        {numberFormat(totalCapital('paidupCapital'), true)}
      </Text>
      <Text style={[styles.overlay, { left: 493, top: 675, width: 52, height: 12 }]}>
        {!_.isNull(totalCapital('paidupCapital')) && numberFormat(100, true)}%
      </Text>
    </>
  );
};

Page3Overlay.propTypes = {
  fields: PropTypes.shape().isRequired,
};

export default Page3Overlay;
