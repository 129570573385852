import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { View, StyleSheet } from '@react-pdf/renderer';

import Text from './Text';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    fontSize: 7,
  },
  rowContainer: {
    flexDirection: 'row',
    height: 29.25,
    alignItems: 'center',
  },
});

const Page4Overlay = ({ fields }) => (
  <>
    <Text style={[styles.overlay, { left: 120, top: 67, width: 440, height: 15, fontSize: 10 }]} bold>
      {fields.companyName}
    </Text>
    <View style={[styles.overlay, { left: 47, top: 119 }]}>
      {_.map(fields.officers, (officer, key) => (
        <View key={key} style={[styles.rowContainer]}>
          <Text style={{ width: 139, fontSize: 6, paddingRight: 3 }}>{officer.name}</Text>
          <Text style={{ width: 61 }}>{officer.nationality}</Text>
          <Text style={{ width: 35 }}>{officer.incorporator}</Text>
          <Text style={{ width: 32 }}>{officer.board}</Text>
          <Text style={{ width: 35 }}>{officer.gender}</Text>
          <Text style={{ width: 44 }}>{officer.stockholder}</Text>
          <Text style={{ width: 46 }}>{officer.officer}</Text>
          <Text style={{ width: 30 }}>{officer.execComm}</Text>
          <Text style={{ width: 88 }}>{officer.tin}</Text>
        </View>
      ))}
    </View>
  </>
);

Page4Overlay.propTypes = {
  fields: PropTypes.shape().isRequired,
};

export default Page4Overlay;
