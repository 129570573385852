import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';

import Share from './Share';
import { Grid, numberFormat } from '../../CustomComponents';

const SharesSubscribed = ({ sharesSubscribed, setValue, displayError }) => (
  <Grid container direction="column" noBorder>
    {_.times(3, index => (
      <Grid key={index} item style={{ padding: 0 }}>
        <Share share={sharesSubscribed[index]} setValue={share => setValue(share, index)} displayError={displayError} />
      </Grid>
    ))}
    <Grid item style={{ padding: 0 }}>
      <Grid container noBorder>
        <Grid item xs={4}>
          <Typography variant="button">Total</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button">{numberFormat(sharesSubscribed.numberTotal)}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="button">{numberFormat(sharesSubscribed.amountTotal)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

SharesSubscribed.defaultProps = {
  sharesSubscribed: {},
  setValue: () => {},
  displayError: false,
};

SharesSubscribed.propTypes = {
  sharesSubscribed: PropTypes.shape(),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

export default SharesSubscribed;
