import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MatSelect from '@material-ui/core/Select';
import { withStyles, makeStyles } from '@material-ui/styles';

const CustomSelect = withStyles({
  outlined: {
    '&': {
      padding: '3px 24px 3px 10px',
    },
  },
})(MatSelect);

const styles = makeStyles({
  error: {
    '& > fieldset': {
      borderColor: 'red',
    },
  },
});

const Select = ({ children, onChange, value, displayError, ...props }) => {
  const classes = styles();

  return (
    <CustomSelect
      className={displayError ? classes.error : ''}
      onChange={e => {
        e.persist();
        onChange(e.target.value);
      }}
      value={!_.isUndefined(value) ? value : ''}
      {...props}
    >
      {children}
    </CustomSelect>
  );
};

Select.defaultProps = {
  onChange: () => {},
  value: '',
  displayError: false,
};

Select.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  displayError: PropTypes.bool,
};

export default Select;
