import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import axiosConfig from '../../config/axios';
import { AUTH_API_ROOT } from '../../globals';
import { submitData } from '../../actions';

const CompaniesList = ({ companies, history, setInitialData, setVal }) => (
  <List>
    {_.map(companies, company => (
      <ListItem
        button
        onClick={async () => {
          setVal({ loading: true });
          const { status, data } = await axiosConfig.get(`${AUTH_API_ROOT}/companies/${company.id}`);

          if (status === 200) {
            setInitialData({ companyName: data.name, ...data });
            history.push(`/${company.id}`);
            return;
          }
          setVal({ loading: false });
        }}
      >
        <ListItemText primary={company.name} />
      </ListItem>
    ))}
  </List>
);

CompaniesList.defaultProps = {
  companies: [],
  setVal: () => {},
};

CompaniesList.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape()),
  history: PropTypes.shape().isRequired,
  setInitialData: PropTypes.func.isRequired,
  setVal: PropTypes.func,
};

const mapActionsToProps = {
  setInitialData: submitData,
};

const routedComponent = withRouter(CompaniesList);

export default connect(null, mapActionsToProps)(routedComponent);
