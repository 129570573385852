import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

moment.locale('en');

const Picker = ({ children }) => (
  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="en">
    {children}
  </MuiPickersUtilsProvider>
);

Picker.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Picker;
