import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import MultipleFields from '../MultipleFields';
import { numberFormat } from '../CustomComponents';

const imgSrc = require('../../images/Page_3_GIS.png');

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'fit-content',
    position: 'relative',
  },
  input: {
    position: 'absolute',
    margin: 0,
  },
  totalsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
});

const Page3 = ({ companyName, fields, setValue, displayError }) => {
  const classes = styles();

  const computeColumnTotal = (newField, oldFields) => {
    const totals = [_.first(_.values(newField)).total, ..._.map(_.omit(oldFields, _.keys(newField)), 'total')];

    return !_.isEmpty(_.compact(totals)) ? _.sum(totals) : null;
  };

  const totalCapital = type => {
    const capitalTotals = fields.totals[type];

    return capitalTotals.filipino || capitalTotals.foreign
      ? _.sum(_.compact([capitalTotals.filipino, capitalTotals.foreign]))
      : null;
  };

  const computeCapitalPercentage = (capitalOfType, capitalTotal) => {
    const capital = capitalOfType;

    _.map(capital, (val, key) => {
      capital[key].percentage = _.multiply(_.divide(val.total, capitalTotal), 100);
    });

    return capital;
  };

  const recomputePercentages = (type, subType, rowData) => {
    const capitalTotal = _.sum(
      _.compact([
        _.first(_.values(rowData)).total,
        ..._.map(_.omit(fields[type][subType], _.keys(rowData)), 'total'),
        ..._.map(fields[type][subType === 'filipino' ? 'foreign' : 'filipino'], 'total'),
      ]),
    );
    let filipinoCapital = _.omit(fields[type].filipino, subType === 'filipino' ? _.keys(rowData) : null);
    let foreignCapital = _.omit(fields[type].foreign, subType === 'foreign' ? _.keys(rowData) : null);

    if (subType === 'filipino') filipinoCapital[_.first(_.keys(rowData))] = _.first(_.values(rowData));
    else foreignCapital[_.first(_.keys(rowData))] = _.first(_.values(rowData));
    filipinoCapital = computeCapitalPercentage(filipinoCapital, capitalTotal);
    foreignCapital = computeCapitalPercentage(foreignCapital, capitalTotal);
    setValue(type, {
      filipino: filipinoCapital,
      foreign: foreignCapital,
    });
    // Fix calculation of totals (convert to 2 decimals first then add)
    setValue('percentages', {
      ...fields.percentages,
      [type]: {
        filipino: _.sum(_.compact(_.map(filipinoCapital, 'percentage'))),
        foreign: _.sum(_.compact(_.map(foreignCapital, 'percentage'))),
      },
    });
  };

  return (
    <div className={classes.container}>
      <img src={imgSrc} alt="Page 3 GIS" />
      <div className={`form-overlay ${classes.input}`}>
        <Typography className={classes.input} style={{ left: 211, top: 159, width: 800 }}>
          {companyName}
        </Typography>
      </div>
      <MultipleFields
        className={classes.input}
        style={{ left: 287, top: 300 }}
        type="capitalStock"
        values={fields.authorizedCapitalStock}
        onChange={authorizedCapitalStock => {
          setValue('authorizedCapitalStock', { ...fields.authorizedCapitalStock, ...authorizedCapitalStock });
          setValue('totals', {
            ...fields.totals,
            authorizedCapitalStock: computeColumnTotal(authorizedCapitalStock, fields.authorizedCapitalStock),
          });
        }}
        count={3}
        displayError={displayError}
      />
      <Typography className={classes.input} style={{ left: 1001, top: 389, width: 172, textAlign: 'right' }}>
        {numberFormat(fields.totals.authorizedCapitalStock)}
      </Typography>
      <MultipleFields
        className={classes.input}
        style={{ left: 185, top: 539 }}
        type="subscribedCapital"
        values={fields.subscribedCapital.filipino}
        onChange={subscribedCapitalFilipino => {
          recomputePercentages('subscribedCapital', 'filipino', subscribedCapitalFilipino);
          setValue('totals', {
            ...fields.totals,
            subscribedCapital: {
              ...fields.totals.subscribedCapital,
              filipino: computeColumnTotal(subscribedCapitalFilipino, fields.subscribedCapital.filipino),
            },
          });
        }}
        count={3}
        displayError={displayError}
        percentageRoundOff={!_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff}
      />
      <div className={`${classes.input} ${classes.totalsContainer}`} style={{ left: 936, top: 627 }}>
        <Typography style={{ width: 131, textAlign: 'right', marginRight: 35 }}>
          {numberFormat(fields.totals.subscribedCapital.filipino)}
        </Typography>
        <Typography>
          {numberFormat(
            !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
              ? Math.round(fields.percentages.subscribedCapital.filipino)
              : fields.percentages.subscribedCapital.filipino,
          )}
        </Typography>
      </div>
      <MultipleFields
        className={classes.input}
        style={{ left: 55, top: 753 }}
        type="foreignSubscribedCapital"
        values={fields.subscribedCapital.foreign}
        onChange={subscribedCapitalForeign => {
          recomputePercentages('subscribedCapital', 'foreign', subscribedCapitalForeign);
          setValue('totals', {
            ...fields.totals,
            subscribedCapital: {
              ...fields.totals.subscribedCapital,
              foreign: computeColumnTotal(subscribedCapitalForeign, fields.subscribedCapital.foreign),
            },
          });
        }}
        count={4}
        displayError={displayError}
        percentageRoundOff={!_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff}
      />
      <div className={`${classes.input} ${classes.totalsContainer}`} style={{ left: 936, top: 865 }}>
        <Typography style={{ width: 131, textAlign: 'right', marginRight: 35 }}>
          {numberFormat(fields.totals.subscribedCapital.foreign)}
        </Typography>
        <Typography>
          {numberFormat(
            !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
              ? Math.round(fields.percentages.subscribedCapital.foreign)
              : fields.percentages.subscribedCapital.foreign,
          )}
        </Typography>
      </div>
      <div className={`${classes.input} ${classes.totalsContainer}`} style={{ left: 936, top: 888 }}>
        <Typography style={{ width: 131, textAlign: 'right', marginRight: 35 }}>
          {numberFormat(totalCapital('subscribedCapital'))}
        </Typography>
        <Typography>{!_.isNull(totalCapital('subscribedCapital')) && numberFormat(100)}</Typography>
      </div>
      <MultipleFields
        className={classes.input}
        style={{ left: 185, top: 1001 }}
        type="paidUpCapital"
        values={fields.paidupCapital.filipino}
        onChange={paidUpCapitalFilipino => {
          recomputePercentages('paidupCapital', 'filipino', paidUpCapitalFilipino);
          setValue('totals', {
            ...fields.totals,
            paidupCapital: {
              ...fields.totals.paidupCapital,
              filipino: computeColumnTotal(paidUpCapitalFilipino, fields.paidupCapital.filipino),
            },
          });
        }}
        count={4}
        displayError={displayError}
        percentageRoundOff={!_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff}
      />
      <div className={`${classes.input} ${classes.totalsContainer}`} style={{ left: 936, top: 1112 }}>
        <Typography style={{ width: 131, textAlign: 'right', marginRight: 35 }}>
          {numberFormat(fields.totals.paidupCapital.filipino)}
        </Typography>
        <Typography>
          {numberFormat(
            !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
              ? Math.round(fields.percentages.paidupCapital.filipino)
              : fields.percentages.paidupCapital.filipino,
          )}
        </Typography>
      </div>
      <MultipleFields
        className={classes.input}
        style={{ left: 55, top: 1198 }}
        type="paidUpCapitalForeign"
        values={fields.paidupCapital.foreign}
        onChange={paidUpCapitalForeign => {
          recomputePercentages('paidupCapital', 'foreign', paidUpCapitalForeign);
          setValue('totals', {
            ...fields.totals,
            paidupCapital: {
              ...fields.totals.paidupCapital,
              foreign: computeColumnTotal(paidUpCapitalForeign, fields.paidupCapital.foreign),
            },
          });
        }}
        count={4}
        displayError={displayError}
        percentageRoundOff={!_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff}
      />
      <div className={`${classes.input} ${classes.totalsContainer}`} style={{ left: 936, top: 1310 }}>
        <Typography style={{ width: 131, textAlign: 'right', marginRight: 35 }}>
          {numberFormat(fields.totals.paidupCapital.foreign)}
        </Typography>
        <Typography>
          {numberFormat(
            !_.isNil(fields.percentageRoundOff) && fields.percentageRoundOff
              ? Math.round(fields.percentages.paidupCapital.foreign)
              : fields.percentages.paidupCapital.foreign,
          )}
        </Typography>
      </div>
      <div className={`${classes.input} ${classes.totalsContainer}`} style={{ left: 936, top: 1332 }}>
        <Typography style={{ width: 131, textAlign: 'right', marginRight: 35 }}>
          {numberFormat(totalCapital('paidupCapital'))}
        </Typography>
        <Typography>{!_.isNull(totalCapital('paidupCapital')) && numberFormat(100)}</Typography>
      </div>
    </div>
  );
};

Page3.defaultProps = {
  companyName: '',
  fields: {},
  setValue: () => {},
  displayError: false,
};

Page3.propTypes = {
  companyName: PropTypes.string,
  fields: PropTypes.shape(),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

const mapStateToProps = ({ initialData: { companyName, tin, registrationDate } }) => ({
  companyName,
  tin,
  registrationDate,
});

export default connect(mapStateToProps)(Page3);
