import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles, withTheme } from '@material-ui/styles';

import Validate from '../components/Validate';
import { Paper, Loading } from '../components/CustomComponents';
import { CompaniesList } from '../components/Companies';
import { logoutUser } from '../actions';

import axiosConfig from '../config/axios';
import { AUTH_API_ROOT } from '../globals';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
  loading: {
    height: 'calc(100vh/3)',
    width: 'calc(100vw/3)',
  },
};

class Companies extends Component {
  state = {
    loading: false,
    companies: [],
  };

  componentDidMount = () => {
    this.setState({ loading: true }, async () => {
      try {
        const { data, status } = await axiosConfig.get(`${AUTH_API_ROOT}/users/${this.props.userId}/companies`);

        if (status === 200) {
          this.setState({ companies: data });
        }
      } catch (error) {
        Promise.reject(error);
      }
      this.setState({ loading: false });
    });
  };

  render = () => (
    <Validate>
      <div className={this.props.classes.root}>
        <Box>
          {this.state.loading ? (
            <Loading className={this.props.classes.loading} />
          ) : (
            <Paper>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <CompaniesList companies={this.state.companies} setVal={val => this.setState(val)} />
                </Grid>
                <Grid item>
                  <Button
                    style={{ marginRight: 16 }}
                    variant="contained"
                    onClick={() => this.props.history.push('/companies/create')}
                    color="primary"
                  >
                    Create GIS Form
                  </Button>
                  <Button
                    variant="contained"
                    onClick={async () => {
                      await this.props.logout();
                      this.props.history.push('/');
                    }}
                  >
                    Logout
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Box>
      </div>
    </Validate>
  );
}

Companies.defaultProps = {
  userId: null,
};

Companies.propTypes = {
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  logout: PropTypes.func.isRequired,
  userId: PropTypes.number,
};

const mapStateToProps = ({ auth: { userId } }) => ({
  userId,
});
const mapActionsToProps = {
  logout: logoutUser,
};

const routedComponent = withRouter(Companies);
const themedComponent = withTheme(routedComponent);
const styledComponent = withStyles(styles)(themedComponent);

export default connect(mapStateToProps, mapActionsToProps)(styledComponent);
