import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

let theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: grey,
  },
});
theme = responsiveFontSizes(theme);

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

Theme.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Theme;
