import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles({
  error: {
    borderColor: 'red',
  },
});

const PageEditNumberField = ({ onChange, optional, displayError, className, ...props }) => {
  const classes = styles();

  return (
    <NumberFormat
      className={`${className} ${_.isEmpty(props.value) && !optional && displayError ? classes.error : null}`}
      onValueChange={({ formattedValue }) => onChange(formattedValue)}
      thousandSeparator
      {...props}
    />
  );
};

PageEditNumberField.defaultProps = {
  onChange: () => {},
  displayError: false,
  optional: false,
  value: '',
  className: '',
};

PageEditNumberField.propTypes = {
  onChange: PropTypes.func,
  displayError: PropTypes.bool,
  optional: PropTypes.bool,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default PageEditNumberField;
