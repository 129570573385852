import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { StyleSheet } from '@react-pdf/renderer';

import CustomText from './Text';
import { formatYear, formatDate, formatMonthDay, isEmptyInput } from '../../services/helpers';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    fontSize: 8,
  },
  leftFields: {
    left: 41,
    width: 404,
  },
  rightFields: {
    left: 445,
    width: 104,
  },
  secField: {
    left: 237,
    width: 113,
    height: 16,
  },
  addressField: {
    left: 350,
    width: 200,
    height: 16,
  },
});

const Text = ({ style, children, bold }) => (
  <CustomText style={style} bold={bold}>
    {children}
  </CustomText>
);

const Page1Overlay = ({ fields }) => {
  const renderSubsidaries = () => {
    let count = -1;

    if (_.isEmpty(fields.affiliates)) {
      return (
        <>
          <Text style={[styles.overlay, styles.leftFields, { top: 585, width: 196, height: 16 }]} bold={false}>
            N/A
          </Text>
          <Text style={[styles.overlay, styles.secField, { top: 585 }]} bold={false}>
            N/A
          </Text>
          <Text style={[styles.overlay, styles.addressField, { top: 585 }]} bold={false}>
            N/A
          </Text>
        </>
      );
    }

    return _.map(fields.affiliates, affiliate => {
      count += 1;
      const position = 585 + count * 16;
      return (
        <>
          <Text style={[styles.overlay, styles.leftFields, { top: position, width: 196, height: 16 }]} bold={false}>
            {isEmptyInput(affiliate.name) ? 'N/A' : affiliate.name}
          </Text>
          <Text style={[styles.overlay, styles.secField, { top: position }]} bold={false}>
            {isEmptyInput(affiliate.secRegistrationNumber) ? 'N/A' : affiliate.secRegistrationNumber}
          </Text>
          <Text style={[styles.overlay, styles.addressField, { top: position }]} bold={false}>
            {isEmptyInput(affiliate.address) ? 'N/A' : affiliate.address}
          </Text>
        </>
      );
    });
  };

  return (
    <>
      <Text style={[styles.overlay, { left: 283, top: 70, width: 40, height: 12 }]}>{formatYear(fields.year)}</Text>
      <Text style={[styles.overlay, styles.leftFields, { top: 280, height: 15 }]}>{fields.companyName}</Text>
      <Text style={[styles.overlay, styles.leftFields, { top: 305, height: 17 }]}>{fields.businessName}</Text>
      <Text style={[styles.overlay, styles.leftFields, { top: 332, height: 18 }]}>{fields.secRegistrationNumber}</Text>
      <Text
        style={[
          styles.overlay,
          styles.leftFields,
          { top: 360, height: 26, justifyContent: 'flex-end', paddingBottom: 3 },
        ]}
      >
        {fields.dateOfAnnualMeeting}
      </Text>
      <Text style={[styles.overlay, styles.leftFields, { top: 397, height: 12 }]}>
        {formatDate(fields.actualDateOfAnnualMeeting)}
      </Text>
      <Text style={[styles.overlay, styles.leftFields, { top: 418, height: 19 }]}>
        {fields.completePrincipalOfficeAddress}
      </Text>
      <Text style={[styles.overlay, styles.leftFields, { top: 446, height: 22 }]}>
        {fields.completeBusinessAddress}
      </Text>
      <Text style={[styles.overlay, styles.leftFields, { top: 477, width: 278, height: 26 }]}>
        {fields.nameOfExternalAuditor}
      </Text>
      <Text style={[styles.overlay, { right: 150, top: 477, width: 127, height: 26 }]}>
        {isEmptyInput(fields.secAccreditationNumber) ? 'Not applicable' : fields.secAccreditationNumber}
      </Text>
      <Text style={[styles.overlay, styles.leftFields, { top: 510, width: 278, height: 28 }]}>
        {fields.primaryPurpose}
      </Text>
      <Text style={[styles.overlay, { right: 150, top: 510, width: 127, height: 28 }]}>
        {fields.industryClassification}
      </Text>
      <Text style={[styles.overlay, styles.rightFields, { top: 280, height: 25 }]}>
        {formatDate(fields.registrationDate)}
      </Text>
      <Text style={[styles.overlay, styles.rightFields, { top: 315, height: 35 }]}>
        {formatMonthDay(fields.fiscalYearEnd)}
      </Text>
      <Text style={[styles.overlay, styles.rightFields, { top: 365, height: 21 }]}>{fields.tin}</Text>
      <Text style={[styles.overlay, styles.rightFields, { top: 396, height: 13 }]}>
        {isEmptyInput(fields.website) ? 'N/A' : fields.website}
      </Text>
      <Text style={[styles.overlay, styles.rightFields, { top: 416, height: 21 }]}>
        {isEmptyInput(fields.email) ? 'N/A' : fields.email}
      </Text>
      <Text style={[styles.overlay, styles.rightFields, { top: 444, height: 24 }]}>
        {isEmptyInput(fields.faxNumber) ? 'N/A' : fields.faxNumber}
      </Text>
      <Text style={[styles.overlay, styles.rightFields, { top: 475, height: 28 }]}>{fields.telephoneNumber}</Text>
      <Text style={[styles.overlay, styles.rightFields, { top: 510, height: 28 }]}>{fields.geographicalCode}</Text>
      <Text style={[styles.overlay, styles.leftFields, { top: 561, width: 196, height: 16 }]} bold={false}>
        {isEmptyInput(fields.parentCompany.name) ? 'N/A' : fields.parentCompany.name}
      </Text>
      <Text style={[styles.overlay, styles.secField, { top: 561 }]} bold={false}>
        {isEmptyInput(fields.parentCompany.secRegistrationNumber) ? 'N/A' : fields.parentCompany.secRegistrationNumber}
      </Text>
      <Text style={[styles.overlay, styles.addressField, { top: 561 }]} bold={false}>
        {isEmptyInput(fields.parentCompany.address) ? 'N/A' : fields.parentCompany.address}
      </Text>
      {renderSubsidaries()}
    </>
  );
};

Page1Overlay.propTypes = {
  fields: PropTypes.shape().isRequired,
};

Text.defaultProps = {
  style: {},
  children: null,
  bold: true,
};

Text.propTypes = {
  style: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape)]),
  children: PropTypes.node,
  bold: PropTypes.bool,
};

export default Page1Overlay;
