import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { StyleSheet } from '@react-pdf/renderer';

import Text from './Text';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    fontSize: 10,
    height: 14,
  },
});

const Page9Overlay = ({ fields }) => (
  <>
    <Text style={[styles.overlay, { left: 61, top: 85, width: 170 }]}>{fields.name}</Text>
    <Text style={[styles.overlay, { left: 341, top: 85, width: 175 }]}>{fields.companyName}</Text>
    <Text style={[styles.overlay, { left: 99, top: 304, width: 29 }]}>{fields.day}</Text>
    <Text style={[styles.overlay, { left: 160, top: 304, width: 63 }]}>{moment(fields.month).format('MMMM')}</Text>
    <Text style={[styles.overlay, { left: 242, top: 304, width: 20 }]}>{fields.year}</Text>
    <Text style={[styles.overlay, { left: 276, top: 304, width: 112 }]}>{fields.location}</Text>
  </>
);

Page9Overlay.defaultProps = {
  fields: {},
};

Page9Overlay.propTypes = {
  fields: PropTypes.shape(),
};

export default Page9Overlay;
