import axios from 'axios';

const instance = () => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    async config => {
      const { headers } = config;

      headers.Authorization = `Bearer ${localStorage.getItem('authToken')}`;

      return config;
    },
    error => {
      Promise.reject(error);
    },
  );

  return axiosInstance;
};

export default instance();
