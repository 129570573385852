import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

import PDFRender from './PDFRender';
import { Paper } from '../CustomComponents';

const styles = makeStyles({
  modal: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  header: {
    backgroundColor: 'black',
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '100vw',
    height: '50px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: '1rem',
  },
});

const PDFViewerModal = ({ currentPage, src, onClose, saved, saveChanges, all, loading, ...props }) => {
  const classes = styles();

  return (
    <Modal {...props} onClose={onClose}>
      <div className={classes.modal}>
        {saved ? (
          <>
            <div className={classes.header}>
              <Button onClick={onClose} variant="outlined" color="primary" size="small" className={classes.button}>
                <CloseIcon />
                Close
              </Button>
            </div>
            <PDFRender currentPage={currentPage} src={src} all={all} />
          </>
        ) : (
          <Paper>
            <Typography variant="button" paragraph>
              You have not saved your changes
            </Typography>
            <Button
              variant="contained"
              onClick={saveChanges}
              color="primary"
              className={classes.button}
              disabled={loading}
            >
              Save Changes
            </Button>
            <Button variant="contained" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
          </Paper>
        )}
      </div>
    </Modal>
  );
};

PDFViewerModal.defaultProps = {
  currentPage: '',
  src: '',
  onClose: () => {},
  props: {},
  saved: false,
  saveChanges: () => {},
  all: false,
  loading: false,
};

PDFViewerModal.propTypes = {
  currentPage: PropTypes.string,
  src: PropTypes.string,
  onClose: PropTypes.func,
  props: PropTypes.shape(),
  saved: PropTypes.bool,
  saveChanges: PropTypes.func,
  all: PropTypes.bool,
  loading: PropTypes.bool,
};

export default PDFViewerModal;
