import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import { PageEditTextField as TextField } from '../../FormComponents';
import SharesSubscribed from './SharesSubscribed';

import { convertToNumber, validInputs } from '../../../services/helpers';
import { numberFormat, Grid } from '../../CustomComponents';

const styles = {
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: 129,
  },
  columnContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  rowContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

class Stockholder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.stockholder.name,
      nationality: props.stockholder.nationality,
      address: props.stockholder.address,
      sharesSubscribed: props.stockholder.sharesSubscribed,
      tin: props.stockholder.tin,
      percentage: props.stockholder.percentage,
    };
  }

  changeField = (name, value) => this.setState({ [name]: value }, () => this.props.setValue(this.state));

  render = () => {
    const error = !validInputs(_.omit(this.state, ['percentage', 'sharesSubscribed'])) && this.props.displayError;

    return (
      <Grid container noBorder>
        <Grid item xs={3} className={this.props.classes.columnContainer}>
          <TextField
            value={this.state.name}
            placeholder="Name"
            onChange={name => this.changeField('name', name)}
            variant="outlined"
            displayError={error}
          />
          <TextField
            value={this.state.nationality}
            placeholder="Nationality"
            onChange={nationality => this.changeField('nationality', nationality)}
            variant="outlined"
          />
          <TextField
            value={this.state.address}
            placeholder="Address"
            onChange={address => this.changeField('address', address)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={5} style={{ padding: 0 }}>
          <Grid container noBorder>
            <Grid item xs={9} style={{ padding: 0 }}>
              <SharesSubscribed
                sharesSubscribed={this.state.sharesSubscribed}
                setValue={(share, index) => {
                  const otherShares = _.omit(this.state.sharesSubscribed, [
                    _.toString(index),
                    'numberTotal',
                    'amountTotal',
                  ]);
                  const shares = [..._.map(otherShares), share];
                  const numberItems = _.compact(_.map(shares, item => convertToNumber(item.number)));
                  const amountItems = _.compact(_.map(shares, item => convertToNumber(item.amount)));
                  const sharesSubscribed = {
                    ...otherShares,
                    [index]: share,
                    numberTotal: !_.isEmpty(numberItems) ? _.sum(numberItems) : null,
                    amountTotal: !_.isEmpty(amountItems) ? _.sum(amountItems) : null,
                  };

                  this.changeField('sharesSubscribed', sharesSubscribed);
                }}
                displayError={error}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.rowContainer} style={{ padding: 0 }}>
              <Typography>
                {numberFormat(
                  this.props.percentageRoundOff ? Math.round(this.state.percentage) : this.state.percentage,
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} className={this.props.classes.rowContainer}>
          <Typography>{numberFormat(this.state.sharesSubscribed.amountTotal)}</Typography>
        </Grid>
        <Grid item xs={2} className={this.props.classes.rowContainer}>
          <TextField
            value={this.state.tin}
            placeholder="TIN"
            onChange={tin => this.changeField('tin', tin)}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  };
}

Stockholder.defaultProps = {
  stockholder: {
    name: '',
    nationality: '',
    address: '',
    sharesSubscribed: {},
    tin: '',
    percentage: null,
  },
  setValue: () => {},
  displayError: false,
  percentageRoundOff: false,
};

Stockholder.propTypes = {
  stockholder: PropTypes.shape({
    name: PropTypes.string,
    nationality: PropTypes.string,
    address: PropTypes.string,
    sharesSubscribed: PropTypes.shape(),
    tin: PropTypes.string,
    percentage: PropTypes.number,
  }),
  setValue: PropTypes.func,
  classes: PropTypes.shape().isRequired,
  displayError: PropTypes.bool,
  percentageRoundOff: PropTypes.bool,
};

export default withStyles(styles)(Stockholder);
