import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import _ from 'lodash';

const MatTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      height: 27,
    },
    '& .MuiOutlinedInput-root > .MuiInputBase-input': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
})(TextField);

const PageEditTextField = ({ onChange, optional, displayError, ...props }) => (
  <MatTextField
    {...props}
    error={_.isEmpty(props.value) && !optional && displayError}
    onChange={e => {
      e.persist();
      onChange(e.target.value);
    }}
  />
);

PageEditTextField.defaultProps = {
  onChange: () => {},
  optional: false,
  displayError: false,
  value: '',
};

PageEditTextField.propTypes = {
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  displayError: PropTypes.bool,
  value: PropTypes.string,
};

export default PageEditTextField;
