import React from 'react';
import PropTypes from 'prop-types';
import { Text as PdfText, View, Font } from '@react-pdf/renderer';

import SerifRegular from '../../fonts/Source_Serif_Pro/SourceSerifPro-Regular.ttf';
import SerifBold from '../../fonts/Source_Serif_Pro/SourceSerifPro-Bold.ttf';

Font.register({ family: 'Serif', fonts: [{ src: SerifRegular }, { src: SerifBold, fontWeight: 'bold' }] });

const Text = ({ style, children, bold }) => (
  <View style={[{ alignItems: 'center', justifyContent: 'center' }, style]}>
    <PdfText style={{ fontFamily: 'Serif', fontWeight: bold ? 'bold' : 'normal' }}>{children}</PdfText>
  </View>
);

Text.defaultProps = {
  style: {},
  children: null,
  bold: false,
};

Text.propTypes = {
  style: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape)]),
  children: PropTypes.node,
  bold: PropTypes.bool,
};

export default Text;
