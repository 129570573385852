import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import { Grid, Paper, DataDisplay } from '../CustomComponents';
import { Stockholders } from './Page5to7Components';
import { PageEditNumberField as NumberField } from '../FormComponents';
import { incompleteInputs } from '../../services/helpers';

const styles = {
  rowContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
};

class Page5to7 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stockholderCount: _.size(props.fields.stockholders),
    };
  }

  computeStockholdersPercentage = (stockholders, amountTotal) => {
    const newStockholders = stockholders;

    _.map(newStockholders, (val, key) => {
      newStockholders[key].percentage = !_.isNil(val.sharesSubscribed.amountTotal)
        ? _.multiply(_.divide(val.sharesSubscribed.amountTotal, amountTotal), 100)
        : null;
    });

    return newStockholders;
  };

  validStockholders = () => {
    let isValid = true;

    _.map(this.props.fields.stockholders, stockholder => {
      if (incompleteInputs(_.omit(stockholder, ['percentage', 'sharesSubscribed', 'nationality', 'address', 'tin'])))
        isValid = false;
      _.map(_.omit(stockholder.sharesSubscribed, ['numberTotal', 'amountTotal']), share => {
        if (incompleteInputs(share)) isValid = false;
      });
    });

    return isValid;
  };

  render = () => (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <DataDisplay name="Company Name" value={this.props.companyName} />
        </Grid>
        <Grid item xs={6}>
          <DataDisplay name="Total Number of Stockholders" value={this.props.fields.totalNumberOfStockholders} />
        </Grid>
        <Grid item xs={6}>
          <DataDisplay
            name="No. of Stockholders with 100 or more Shares each"
            value={this.props.fields.numberOfStockholdersShares}
          />
        </Grid>
        <Grid item xs={12} className={this.props.classes.rowContainer}>
          <Typography variant="button" style={{ marginRight: 12 }}>
            Total Assets Based on Latest Audited Financial Statements:
          </Typography>
          <NumberField
            style={{ fontSize: 16 }}
            value={this.props.fields.totalAssets}
            placeholder="Total Assets"
            onChange={totalAssets => this.props.setValue('totalAssets', totalAssets)}
            displayError={this.props.displayError}
          />
        </Grid>
        <Grid item xs={12} className={this.props.classes.rowContainer} style={{ justifyContent: 'center' }}>
          <Typography variant="button">Stockholder&apos;s Information</Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: 0 }}>
          <Stockholders
            stockholders={this.props.fields.stockholders}
            setValue={(stockholder, index) => {
              const otherStockholders = _.omit(this.props.fields.stockholders, _.toString(index));
              const amountTotal = _.sum(
                _.compact(_.map([..._.map(otherStockholders), stockholder], item => item.sharesSubscribed.amountTotal)),
              );
              const finalStockholders = this.computeStockholdersPercentage(
                { ...otherStockholders, [index]: stockholder },
                amountTotal,
              );

              this.props.setValue('stockholders', finalStockholders);
              this.props.setValue('totalNumberOfStockholders', _.size(finalStockholders));
              this.props.setValue(
                'numberOfStockholdersShares',
                _.size(_.filter(finalStockholders, item => item.sharesSubscribed.numberTotal >= 100)),
              );
            }}
            displayError={this.props.displayError}
            stockholderCount={this.state.stockholderCount}
            percentageRoundOff={!_.isNil(this.props.fields.percentageRoundOff) && this.props.fields.percentageRoundOff}
          />
        </Grid>
      </Grid>
      <Grid container noBorder>
        <Grid item xs={12} noBorder className={this.props.classes.rowContainer} style={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            style={this.state.stockholderCount > 0 ? { backgroundColor: '#e53935', color: 'white' } : null}
            onClick={() => {
              const otherStockholders = _.omit(
                this.props.fields.stockholders,
                _.toString(this.state.stockholderCount - 1),
              );
              let index = 0;
              const remappedStockholders = _.fromPairs(
                _.map(otherStockholders, stockholder => {
                  index += 1;

                  return [index - 1, stockholder];
                }),
              );
              const finalStockholders = this.computeStockholdersPercentage(
                remappedStockholders,
                _.sum(_.compact(_.map(remappedStockholders, item => item.sharesSubscribed.amountTotal))),
              );

              this.props.setValue('stockholders', finalStockholders);
              this.props.setValue('totalNumberOfStockholders', _.size(finalStockholders));
              this.props.setValue(
                'numberOfStockholdersShares',
                _.size(_.filter(finalStockholders, item => item.sharesSubscribed.numberTotal >= 100)),
              );
              this.setState(({ stockholderCount }) => ({ stockholderCount: stockholderCount - 1 }));
            }}
            disabled={this.state.stockholderCount <= 0}
          >
            Delete Latest Entry
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 8 }}
            color="primary"
            onClick={() => {
              this.props.setValue('stockholders', {
                ...this.props.fields.stockholders,
                [this.state.stockholderCount]: {
                  name: '',
                  nationality: '',
                  address: '',
                  sharesSubscribed: {},
                  tin: '',
                  percentage: null,
                },
              });
              this.setState(({ stockholderCount }) => ({ stockholderCount: stockholderCount + 1 }));
            }}
            disabled={this.state.stockholderCount >= 21 || !this.validStockholders()}
          >
            Add Stockholder Field
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

Page5to7.defaultProps = {
  companyName: '',
  fields: {},
  setValue: () => {},
  displayError: false,
};

Page5to7.propTypes = {
  companyName: PropTypes.string,
  fields: PropTypes.shape(),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ initialData: { companyName } }) => ({
  companyName,
});

const styledComponent = withStyles(styles)(Page5to7);

export default connect(mapStateToProps)(styledComponent);
