import axios from 'axios';
import _ from 'lodash';

import axiosConfig from '../config/axios';

import { AUTH_API_ROOT } from '../globals';
import { AUTH_VALIDATE, AUTH_VALIDATE_CLEAR, AUTH_LOGIN } from './types';

const validateUser = () => async dispatch => {
  try {
    const { status } = await axiosConfig.get(`${AUTH_API_ROOT}/validate`);

    if (status === 200) {
      dispatch({ type: AUTH_VALIDATE, payload: true });
    }
  } catch (error) {
    dispatch({ type: AUTH_VALIDATE, payload: false });
  }
};

const loginUser = (email, password) => async dispatch => {
  try {
    const { data, status, headers } = await axios.post(`${AUTH_API_ROOT}/login`, { user: { email, password } });

    if (status === 200) {
      await localStorage.setItem('authToken', _.split(headers.authorization, ' ')[1]);
      dispatch({ type: AUTH_LOGIN, payload: data.id });
    }
  } catch (error) {
    Promise.reject(error);
  }
};

const logoutUser = () => async dispatch => {
  await localStorage.removeItem('authToken');
  dispatch({ type: AUTH_VALIDATE_CLEAR });
};

const clearValidation = () => ({ type: AUTH_VALIDATE_CLEAR });

export { validateUser, loginUser, logoutUser, clearValidation };
