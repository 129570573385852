import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';

import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5to7 from './Page5to7';
import Page8 from './Page8';
import Page9 from './Page9';
import BOD from './BOD';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'fit-content',
    width: '1275px',
    alignItems: 'center',
  },
});

const EditableForm = ({ currentPage, setValue, fields, displayError }) => {
  const classes = styles();

  const renderPage = () => {
    switch (currentPage) {
      case 'Page 1':
        return <Page1 setValue={setValue} fields={fields} displayError={displayError} />;
      case 'Page 2':
        return <Page2 setValue={setValue} fields={fields} displayError={displayError} />;
      case 'Page 3':
        return <Page3 setValue={setValue} fields={fields} displayError={displayError} />;
      case 'Page 4':
        return <Page4 setValue={setValue} fields={fields} displayError={displayError} />;
      case 'Page 5-7':
        return <Page5to7 setValue={setValue} fields={fields} displayError={displayError} />;
      case 'Page 8':
        return <Page8 setValue={setValue} fields={fields} displayError={displayError} />;
      case 'Page 9':
        return <Page9 setValue={setValue} fields={fields} displayError={displayError} />;
      case 'BOD':
        return <BOD setValue={setValue} fields={fields} displayError={displayError} />;
      default:
        return null;
    }
  };

  return <div className={classes.container}>{renderPage()}</div>;
};

EditableForm.defaultProps = {
  currentPage: '',
  setValue: () => {},
  fields: {},
  displayError: false,
};

EditableForm.propTypes = {
  currentPage: PropTypes.string,
  setValue: PropTypes.func,
  fields: PropTypes.shape(),
  displayError: PropTypes.bool,
};

const mapStateToProps = ({ initialData: { companyName } }) => ({
  companyName,
});

export default connect(mapStateToProps)(EditableForm);
