import { PAGES_SELECT, PAGES_CLEAR } from '../actions/types';

const DEFAULT_STATE = {
  currentPage: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case PAGES_SELECT:
      return { ...state, currentPage: action.payload };
    case PAGES_CLEAR:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
