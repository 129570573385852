import { INITIAL_DATA_SUBMIT, INITIAL_DATA_CLEAR } from '../actions/types';

const DEFAULT_STATE = {
  companyName: null,
  tin: null,
  registrationDate: null,
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case INITIAL_DATA_SUBMIT: {
      const { companyName, tin, registrationDate } = action.payload;

      return {
        ...state,
        companyName,
        tin,
        registrationDate,
      };
    }
    case INITIAL_DATA_CLEAR:
      return { DEFAULT_STATE };
    default:
      return state;
  }
};
