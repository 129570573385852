import React from 'react';
import PropTypes from 'prop-types';
import MatTextField from '@material-ui/core/TextField';

import InputContainer from './InputContainer';

const TextField = ({ error, onChange, noMargin, ...props }) => (
  <InputContainer error={error} noMargin={noMargin}>
    <MatTextField
      {...props}
      onChange={e => {
        e.persist();
        onChange(e.target.value);
      }}
    />
  </InputContainer>
);

TextField.defaultProps = {
  error: null,
  onChange: () => {},
  noMargin: false,
};

TextField.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func,
  noMargin: PropTypes.bool,
};

export default TextField;
