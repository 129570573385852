import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PageEditTextField as TextField, PageEditNumberField as NumberField } from '../../FormComponents';
import { Grid } from '../../CustomComponents';
import { validInputs } from '../../../services/helpers';

class Share extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: props.share.type,
      number: props.share.number,
      amount: props.share.amount,
    };
  }

  changeField = (name, value) => this.setState({ [name]: value }, () => this.props.setValue(this.state));

  render = () => {
    const error = !validInputs(this.state) && this.props.displayError;

    return (
      <Grid container noBorder>
        <Grid item xs={4} style={{ padding: 0 }}>
          <TextField
            value={this.state.type}
            placeholder="Type"
            onChange={type => this.changeField('type', type)}
            variant="outlined"
            displayError={error}
          />
        </Grid>
        <Grid item xs={4} style={{ padding: 0 }}>
          <NumberField
            style={{ width: 'calc(100% - 2px)', height: 'calc(100% - 2px)', border: 0, fontSize: 16 }}
            value={this.state.number}
            placeholder="Number"
            onChange={number => this.changeField('number', number)}
            displayError={error}
          />
        </Grid>
        <Grid item xs={4} style={{ padding: 0 }}>
          <NumberField
            style={{ width: 'calc(100% - 2px)', height: 'calc(100% - 2px)', border: 0, fontSize: 16 }}
            value={this.state.amount}
            placeholder="Amount"
            onChange={amount => this.changeField('amount', amount)}
            displayError={error}
          />
        </Grid>
      </Grid>
    );
  };
}

Share.defaultProps = {
  share: {
    type: '',
    number: '',
    amount: '',
  },
  setValue: () => {},
  displayError: false,
};

Share.propTypes = {
  share: PropTypes.shape({
    type: PropTypes.string,
    number: PropTypes.string,
    amount: PropTypes.string,
  }),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

export default Share;
