import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import DependentFields from '../DependentFields';
import { numberFormat } from '../CustomComponents';

import { hasInput, convertToNumber } from '../../services/helpers';

const styles = () => ({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginBottom: 3.5,
  },
});

class PaidUpCapital extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nationality: this.props.value.nationality,
      numberOfStockholders: this.props.value.numberOfStockholders,
      typeOfShare: this.props.value.typeOfShare,
      numberOfShares: this.props.value.numberOfShares,
      parStatedValue: this.props.value.parStatedValue,
      total: this.props.value.total,
    };
  }

  computeTotal = field => {
    if (_.includes(['numberOfShares', 'parStatedValue'], field)) {
      if (hasInput(this.state.numberOfShares) && hasInput(this.state.parStatedValue)) {
        return _.multiply(convertToNumber(this.state.numberOfShares), convertToNumber(this.state.parStatedValue));
      }
      return null;
    }
    return this.state.total;
  };

  render = () => (
    <div className={this.props.classes.container}>
      <DependentFields
        stock
        options={[
          this.props.foreign && {
            type: 'text',
            field: 'nationality',
            value: this.state.nationality,
            style: { width: 106, marginRight: 25 },
          },
          {
            type: 'number',
            field: 'numberOfStockholders',
            value: this.state.numberOfStockholders,
            style: { width: 72, marginRight: 26 },
          },
          { type: 'text', field: 'typeOfShare', value: this.state.typeOfShare, style: { width: 156, marginRight: 35 } },
          {
            type: 'number',
            field: 'numberOfShares',
            value: this.state.numberOfShares,
            style: { width: 90, marginRight: 88 },
          },
          {
            type: 'number',
            field: 'parStatedValue',
            value: this.state.parStatedValue,
            style: { width: 90, marginRight: 98 },
          },
        ]}
        onChange={(field, value) =>
          this.setState({ [field]: value }, () => {
            const total = this.computeTotal(field);
            this.setState({ total }, () => {
              this.props.onChange({
                [this.props.index]: this.props.foreign ? this.state : _.omit(this.state, 'nationality'),
              });
            });
          })
        }
        displayError={this.props.displayError}
      />
      <Typography style={{ lineHeight: 1, width: 200, textAlign: 'right', marginRight: 40 }}>
        {numberFormat(this.state.total)}
      </Typography>
      <Typography style={{ lineHeight: 1 }}>
        {numberFormat(
          this.props.percentageRoundOff ? Math.round(this.props.value.percentage) : this.props.value.percentage,
        ) || null}
      </Typography>
    </div>
  );
}

PaidUpCapital.defaultProps = {
  value: {},
  onChange: () => {},
  index: 0,
  foreign: false,
  displayError: false,
  percentageRoundOff: false,
};

PaidUpCapital.propTypes = {
  classes: PropTypes.shape().isRequired,
  value: PropTypes.shape({
    nationality: PropTypes.string,
    numberOfStockholders: PropTypes.string,
    typeOfShare: PropTypes.string,
    numberOfShares: PropTypes.string,
    parStatedValue: PropTypes.string,
    total: PropTypes.number,
    percentage: PropTypes.number,
  }),
  onChange: PropTypes.func,
  index: PropTypes.number,
  foreign: PropTypes.bool,
  displayError: PropTypes.bool,
  percentageRoundOff: PropTypes.bool,
};

export default withStyles(styles)(PaidUpCapital);
