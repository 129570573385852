export default {
  year: null,
  businessName: '',
  secRegistrationNumber: '',
  dateOfAnnualMeeting: '',
  actualDateOfAnnualMeeting: null,
  completePrincipalOfficeAddress: '',
  completeBusinessAddress: '',
  nameOfExternalAuditor: '',
  secAccreditationNumber: '',
  primaryPurpose: '',
  industryClassification: '',
  fiscalYearEnd: null,
  website: '',
  email: '',
  faxNumber: '',
  telephoneNumber: '',
  geographicalCode: '',
  parentCompany: {
    name: '',
    secRegistrationNumber: '',
    address: '',
  },
  affiliates: {},
};
