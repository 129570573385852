import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import Stockholder from './Stockholder';
import { Grid } from '../../CustomComponents';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  rowContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const Stockholders = ({ stockholders, setValue, displayError, stockholderCount, percentageRoundOff }) => {
  const classes = styles();

  return (
    <>
      <Grid container noBorder>
        <Grid item xs={3} className={classes.rowContainer}>
          <Typography variant="button">Name, Nationality and Current Residential Address</Typography>
        </Grid>
        <Grid item xs={5} style={{ padding: 0 }}>
          <Grid container noBorder direction="row">
            <Grid item xs={12} className={classes.rowContainer}>
              <Typography variant="button">Shares Subscribed</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="button">Type</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="button">Number</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="button">Amount (Php)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="button">% of Ownerships</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} className={classes.rowContainer}>
          <Typography variant="button">Amount Paid(Php)</Typography>
        </Grid>
        <Grid item xs={2} className={classes.rowContainer}>
          <Typography variant="button">Tax Identification Number</Typography>
        </Grid>
      </Grid>
      {_.times(stockholderCount, index => (
        <Stockholder
          key={index}
          index={index}
          stockholder={stockholders[index]}
          setValue={stockholder => setValue(stockholder, index)}
          displayError={displayError}
          percentageRoundOff={percentageRoundOff}
        />
      ))}
    </>
  );
};

Stockholders.defaultProps = {
  stockholders: {},
  setValue: () => {},
  displayError: false,
  stockholderCount: 1,
  percentageRoundOff: false,
};

Stockholders.propTypes = {
  stockholders: PropTypes.shape(),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
  stockholderCount: PropTypes.number,
  percentageRoundOff: PropTypes.bool,
};

export default Stockholders;
