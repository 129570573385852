import React from 'react';
import NumberFormat from 'react-number-format';

const numberFormat = (number, renderText = false) => (
  <NumberFormat
    value={number}
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
    displayType="text"
    renderText={renderText ? value => value : null}
  />
);

export default numberFormat;
