const DATE_FORMAT = 'MM/DD/YYYY';
const PAGES = ['Page 1', 'Page 2', 'Page 3', 'Page 4', 'Page 5-7', 'Page 8', 'Page 9', 'BOD'];

const PAGE_1_FIELDS_OMITS = ['secAccreditationNumber', 'parentCompany', 'affiliates', 'website', 'email', 'faxNumber'];
const PAGE_2_FIELDS_OMITS = ['natureOfBusiness'];
const PAGE_3_FIELDS_OMITS = [
  'authorizedCapitalStock',
  'subscribedCapital',
  'paidupCapital',
  'totals',
  'percentageRoundOff',
];
const PAGE_5_TO_7_FIELDS_OMITS = [
  'stockholders',
  'totalNumberOfStockholders',
  'numberOfStockholdersShares',
  'percentageRoundOff',
];
const PAGE_4_FIELDS_OMITS = ['officers'];
const PAGE_8_FIELDS_OMITS = [
  'investmentInAnotherCorporation',
  'investmentInSecondaryPurposes',
  'treasuryShares',
  'unrestrictedRetainedEarnings',
  'dividendsDeclared',
  'additionalShares',
  'secondaryLicense',
  'totalAnnualCompensation',
  'totalNoOfficers',
  'totalNoRank',
  'totalManpower',
];
const BOD_FIELDS_OMITS = ['beneficialOwners'];

const AUTH_API_ROOT = 'https://api.gis.full-suite.com';

export {
  DATE_FORMAT,
  PAGES,
  PAGE_1_FIELDS_OMITS,
  PAGE_2_FIELDS_OMITS,
  PAGE_3_FIELDS_OMITS,
  PAGE_4_FIELDS_OMITS,
  PAGE_5_TO_7_FIELDS_OMITS,
  PAGE_8_FIELDS_OMITS,
  BOD_FIELDS_OMITS,
  AUTH_API_ROOT,
};
