import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import BeneficialOwner from './BeneficialOwner';
import { Grid, Paper } from '../../CustomComponents';

const styles = makeStyles({
  container: {
    height: 307,
  },
});

const BeneficialOwners = ({ className, style, beneficialOwners, setValue, displayError }) => {
  const classes = styles();

  return (
    <div className={className} style={style}>
      <Paper className={classes.container}>
        <Grid container>
          <Grid item xs={12} style={{ padding: 0 }}>
            <Grid container noBorder>
              <Grid item xs={2}>
                <Typography>
                  Complete Name(Surname, Given Name, Middle Name, Name Extension (i.e., Jr., Sr., III)
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Specific Residential Address</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Nationality</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>Date of Birth</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>Tax Identification No.</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>% of Ownership1 / % of Voting Rights2</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>Type of Beneficial Owner3</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>Category of Beneficial Ownership</Typography>
              </Grid>
            </Grid>
          </Grid>
          {_.times(3, index => (
            <Grid item xs={12} key={index} style={{ padding: 0 }}>
              <BeneficialOwner
                beneficialOwner={beneficialOwners[index]}
                setValue={beneficialOwner => {
                  const otherBeneficialOwners = _.omit(beneficialOwners, _.toString(index));
                  setValue({ ...otherBeneficialOwners, [index]: beneficialOwner });
                }}
                displayError={displayError}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  );
};

BeneficialOwners.defaultProps = {
  className: '',
  style: {},
  beneficialOwners: {},
  setValue: () => {},
  displayError: false,
};

BeneficialOwners.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape(),
  beneficialOwners: PropTypes.shape(),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

export default BeneficialOwners;
