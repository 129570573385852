import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { View, StyleSheet } from '@react-pdf/renderer';

import Text from './Text';
import { numberFormat } from '../CustomComponents';
import { formatDate } from '../../services/helpers';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    fontSize: 10,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const BODOverlay = ({ fields }) => {
  const addressFontSize = address => {
    if (_.size(address) <= 48) return 8;
    if (_.size(address) < 90) return 6;
    return 5;
  };

  return (
    <>
      <Text style={[styles.overlay, { left: 155, top: 41 }]}>{fields.secRegistrationNumber}</Text>
      <Text style={[styles.overlay, { left: 120, top: 52 }]}>{fields.companyName}</Text>
      <View style={[styles.overlay, { left: 37, top: 645 }]}>
        {_.map(fields.beneficialOwners, beneficialOwner => (
          <View key={beneficialOwner.name} style={[styles.rowContainer, { height: 26.5 }]}>
            <Text style={{ width: 85, fontSize: 8, paddingLeft: 3, paddingRight: 3 }}>{beneficialOwner.name}</Text>
            <Text style={{ width: 78, fontSize: addressFontSize(beneficialOwner.address) }}>
              {beneficialOwner.address}
            </Text>
            <Text style={{ width: 54, fontSize: 8 }}>{beneficialOwner.nationality}</Text>
            <Text style={{ width: 50, fontSize: 8 }}>
              {!_.isNil(beneficialOwner.dateOfBirth) && formatDate(beneficialOwner.dateOfBirth)}
            </Text>
            <Text style={{ width: 58, fontSize: 7 }}>{beneficialOwner.tin}</Text>
            <Text style={{ width: 59, fontSize: 8 }}>{numberFormat(beneficialOwner.percentOfOwnership, true)}%</Text>
            <Text style={{ width: 52, fontSize: 8 }}>{beneficialOwner.type}</Text>
            <Text style={{ width: 67, fontSize: 8 }}>{beneficialOwner.category}</Text>
          </View>
        ))}
      </View>
    </>
  );
};

BODOverlay.defaultProps = {
  fields: {},
};

BODOverlay.propTypes = {
  fields: PropTypes.shape(),
};

export default BODOverlay;
