import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

import { Paper } from '../components/CustomComponents';
import { TextField, PasswordTextField } from '../components/FormComponents';
import { incompleteInputs } from '../services/helpers';
import { loginUser } from '../actions';

import { AUTH_API_ROOT } from '../globals';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
  paper: {
    width: 'calc(100vw/3)',
  },
};

class SignupPage extends Component {
  state = {
    email: '',
    password: '',
    passwordConfirmation: '',
    loading: false,
  };

  render = () => (
    <Box className={this.props.classes.root}>
      <Paper className={this.props.classes.paper}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography variant="h4">Sign Up</Typography>
          </Grid>
          <Grid item>
            <TextField value={this.state.email} placeholder="Email" onChange={email => this.setState({ email })} />
          </Grid>
          <Grid item>
            <PasswordTextField
              value={this.state.password}
              placeholder="Password"
              onChange={password => this.setState({ password })}
            />
          </Grid>
          <Grid item>
            <PasswordTextField
              value={this.state.passwordConfirmation}
              placeholder="Password Confirmation"
              onChange={passwordConfirmation => this.setState({ passwordConfirmation })}
            />
          </Grid>
          <Grid item>
            <Button
              style={{ marginRight: 16 }}
              color="primary"
              disabled={incompleteInputs(this.state) || this.state.loading}
              variant="contained"
              onClick={() => {
                this.setState({ loading: true }, async () => {
                  try {
                    const { status } = await axios.post(`${AUTH_API_ROOT}/signup`, {
                      user: {
                        email: this.state.email,
                        password: this.state.password,
                        password_confirmation: this.state.passwordConfirmation,
                      },
                    });

                    if (status === 200) {
                      await this.props.login(this.state.email, this.state.password);
                      this.props.history.push('/companies');
                    }
                  } catch (error) {
                    Promise.reject(error);
                  }
                  this.setState({ loading: false });
                });
              }}
            >
              Submit
            </Button>
            <Button variant="contained" disabled={this.state.loading} onClick={() => this.props.history.push('/')}>
              Back
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

SignupPage.defaultProps = {
  login: () => {},
};

SignupPage.propTypes = {
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  login: PropTypes.func,
};

const mapActionsToProps = {
  login: loginUser,
};

const styledComponent = withStyles(styles)(SignupPage);
const routedComponent = withRouter(styledComponent);

export default connect(null, mapActionsToProps)(routedComponent);
