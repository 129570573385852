import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';

import { Grid } from '../../CustomComponents';
import Officer from './Officer';

const Officers = ({ officers, officersCount, setValue, displayError }) => (
  <>
    <Grid container noBorder>
      <Grid item xs={2}>
        <Typography variant="button">Name/Current Residential Address</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="button">Nationality</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="button">Inc&apos;r</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="button">Board</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="button">Gender</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="button">Stockholder</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="button">Officer</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="button">Exec. Comm</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="button">Tax Identification Number</Typography>
      </Grid>
    </Grid>
    {_.times(officersCount, officerIndex => (
      <Officer
        officer={officers[officerIndex]}
        setValue={officer => setValue(officer, officerIndex)}
        displayError={displayError}
      />
    ))}
  </>
);

Officers.defaultProps = {
  officers: {},
  officersCount: 0,
  setValue: () => {},
  displayError: false,
};

Officers.propTypes = {
  officers: PropTypes.shape(),
  officersCount: PropTypes.number,
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

export default Officers;
