import { combineReducers } from 'redux';

import initialData from './initialDataReducer';
import pages from './pagesReducer';
import fields from './fieldsReducer';
import auth from './authReducer';

export default combineReducers({
  initialData,
  pages,
  fields,
  auth,
});
