import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/styles';

import {
  PageEditTextField as TextField,
  PageEditNumberField as NumberField,
  PageEditDatePicker as CustomDatePicker,
} from '../FormComponents';
import { numberFormat } from '../CustomComponents';
import { convertToNumber, allEmptyInputs } from '../../services/helpers';

import { DATE_FORMAT } from '../../globals';

const DatePicker = withStyles({
  root: {
    '&': {
      margin: 0,
      width: 194,
    },
  },
})(CustomDatePicker);

const imgSrc = require('../../images/Page_8_GIS.png');

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'fit-content',
    position: 'relative',
  },
  input: {
    position: 'absolute',
    margin: 0,
  },
  rowContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  columnContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
});

const Page8 = ({ companyName, fields, setValue, displayError }) => {
  const classes = styles();

  const assignSubFields = (field, key, newValue) => {
    if (field === 'dividendsDeclared' && _.first(_.keys(newValue)) === 'amount') {
      const omittedAmount = convertToNumber(_.first(_.values(newValue)));
      const otherAmounts = _.map(_.omit(fields[field], ['total', key]), value => convertToNumber(value.amount));
      const totalAmount = _.sum(_.compact([omittedAmount, ...otherAmounts]));

      setValue(field, {
        ...fields[field],
        [key]: {
          ...fields[field][key],
          [_.first(_.keys(newValue))]: _.first(_.values(newValue)),
        },
        total: {
          amount: totalAmount,
        },
      });
      return;
    }
    setValue(field, {
      ...fields[field],
      [key]: {
        ...fields[field][key],
        [_.first(_.keys(newValue))]: _.first(_.values(newValue)),
      },
    });
  };

  const checkAssociatedEmpty = field => {
    let hasEmpty = false;
    let hasFilled = false;

    _.map(field, item => {
      if (_.isNil(item) || item === '') hasEmpty = true;
      else hasFilled = true;
    });

    return !((hasEmpty && !hasFilled) || (!hasEmpty && hasFilled)) && (hasEmpty || hasFilled);
  };

  return (
    <div className={classes.container}>
      <img src={imgSrc} alt="Page 8 GIS" />
      <Typography className={classes.input} style={{ left: 275, top: 239 }}>
        {companyName}
      </Typography>
      <div className={classes.input} style={{ left: 617, top: 339 }}>
        {_.map(fields.investmentInAnotherCorporation, (value, key) => (
          <div key={key} className={classes.rowContainer} style={{ marginBottom: 18 }}>
            <NumberField
              style={{ height: 20, marginRight: 151, fontSize: 18 }}
              placeholder="Amount"
              value={value.amount}
              onChange={amount => assignSubFields('investmentInAnotherCorporation', key, { amount })}
              displayError={displayError && checkAssociatedEmpty(value)}
            />
            <DatePicker
              value={value.date}
              placeholder={DATE_FORMAT}
              onChange={date => assignSubFields('investmentInAnotherCorporation', key, { date })}
              invalidDateMessage={null}
              displayError={displayError && checkAssociatedEmpty(value)}
            />
          </div>
        ))}
      </div>
      <div className={`${classes.input} ${classes.rowContainer}`} style={{ top: 636, left: 120 }}>
        <TextField
          style={{ width: 600, marginRight: 48 }}
          value={fields.investmentInSecondaryPurposes.name}
          placeholder="Investment of Corporate Funds in Activities Under its Secondary Purposes"
          onChange={name =>
            setValue('investmentInSecondaryPurposes', {
              ...fields.investmentInSecondaryPurposes,
              name,
            })
          }
          variant="outlined"
          displayError={displayError}
        />
        <DatePicker
          style={{ marginRight: 47 }}
          value={fields.investmentInSecondaryPurposes.boardResolutionDate}
          placeholder={DATE_FORMAT}
          onChange={boardResolutionDate =>
            setValue('investmentInSecondaryPurposes', {
              ...fields.investmentInSecondaryPurposes,
              boardResolutionDate,
            })
          }
          displayError={displayError}
        />
        <DatePicker
          value={fields.investmentInSecondaryPurposes.stockholderRatificationDate}
          placeholder={DATE_FORMAT}
          onChange={stockholderRatificationDate =>
            setValue('investmentInSecondaryPurposes', {
              ...fields.investmentInSecondaryPurposes,
              stockholderRatificationDate,
            })
          }
          displayError={displayError}
        />
      </div>
      <div className={`${classes.input} ${classes.rowContainer}`} style={{ top: 763, left: 120 }}>
        <TextField
          style={{ width: 600, marginRight: 48 }}
          value={fields.treasuryShares.name}
          placeholder="Treasury Shares"
          onChange={name =>
            setValue('treasuryShares', {
              ...fields.treasuryShares,
              name,
            })
          }
          variant="outlined"
          displayError={displayError}
        />
        <NumberField
          style={{ width: 194, marginRight: 47, fontSize: 18 }}
          value={fields.treasuryShares.numberOfShares}
          placeholder="No. of Shares"
          onChange={numberOfShares =>
            setValue('treasuryShares', {
              ...fields.treasueryShares,
              numberOfShares,
            })
          }
          displayError={displayError}
        />
        <NumberField
          style={{ width: 194, fontSize: 18 }}
          value={fields.treasuryShares.percentage}
          placeholder="Percentage"
          onChange={percentage =>
            setValue('treasuryShares', {
              ...fields.treasuryShares,
              percentage,
            })
          }
          displayError={displayError}
        />
      </div>
      <NumberField
        className={classes.input}
        style={{ top: 802, left: 892, width: 229, fontSize: 16 }}
        value={fields.unrestrictedRetainedEarnings}
        placeholder="Unrestricted Retained Earnings"
        onChange={unrestrictedRetainedEarnings =>
          setValue('unrestrictedRetainedEarnings', unrestrictedRetainedEarnings)
        }
        displayError={displayError}
      />
      <div className={classes.input} style={{ top: 899, left: 733 }}>
        {_.map(_.omit(fields.dividendsDeclared, 'total'), (value, key) => (
          <div key={key} className={classes.rowContainer}>
            <NumberField
              style={{ marginRight: 150 }}
              value={value.amount}
              placeholder="Amount"
              onChange={amount => assignSubFields('dividendsDeclared', key, { amount })}
              displayError={displayError && checkAssociatedEmpty(value)}
            />
            <DatePicker
              value={value.date}
              placeholder={DATE_FORMAT}
              onChange={date => assignSubFields('dividendsDeclared', key, { date })}
              displayError={displayError && checkAssociatedEmpty(value)}
            />
          </div>
        ))}
        <Typography style={{ width: '50%', textAlign: 'right' }}>
          {!allEmptyInputs(_.map(_.omit(fields.dividendsDeclared, 'total'), 'amount')) &&
            numberFormat(fields.dividendsDeclared.total.amount)}
        </Typography>
      </div>
      <div className={classes.input} style={{ top: 1063, left: 106 }}>
        {_.times(4, index => {
          const isPresent = !_.isUndefined(fields.additionalShares[index]);

          return (
            <div key={index} className={classes.rowContainer}>
              <DatePicker
                style={{ height: 18, marginRight: 134 }}
                value={
                  isPresent && !_.isUndefined(fields.additionalShares[index].date)
                    ? fields.additionalShares[index].date
                    : null
                }
                placeholder={DATE_FORMAT}
                onChange={date => assignSubFields('additionalShares', index, { date })}
                invalidDateMessage={null}
                displayError={displayError && checkAssociatedEmpty(fields.additionalShares[index])}
              />
              <NumberField
                style={{ fontSize: 16, marginRight: 277 }}
                value={isPresent ? fields.additionalShares[index].numberOfShares : ''}
                placeholder="Number Of Shares"
                onChange={numberOfShares => assignSubFields('additionalShares', index, { numberOfShares })}
                displayError={displayError && checkAssociatedEmpty(fields.additionalShares[index])}
              />
              <NumberField
                style={{ fontSize: 16 }}
                value={isPresent ? fields.additionalShares[index].amount : ''}
                placeholder="Amount"
                onChange={amount => assignSubFields('additionalShares', index, { amount })}
                displayError={displayError && checkAssociatedEmpty(fields.additionalShares[index])}
              />
            </div>
          );
        })}
      </div>
      <div className={`${classes.input} ${classes.rowContainer}`} style={{ top: 1221, left: 320 }}>
        {_.map(fields.secondaryLicense, (value, key) => (
          <div key={key} className={classes.columnContainer} style={{ alignItems: 'center', marginRight: 39 }}>
            <TextField
              style={{ width: 264, marginBottom: 20 }}
              value={value.type}
              placeholder="Type of License"
              onChange={type => assignSubFields('secondaryLicense', key, { type })}
              variant="outlined"
              displayError={displayError && checkAssociatedEmpty(value)}
            />
            <DatePicker
              style={{ marginBottom: 20 }}
              value={value.dateIssued}
              placeholder={DATE_FORMAT}
              onChange={dateIssued => assignSubFields('secondaryLicense', key, { dateIssued })}
              displayError={displayError && checkAssociatedEmpty(value)}
            />
            <DatePicker
              value={value.dateStarted}
              placeholder={DATE_FORMAT}
              onChange={dateStarted => assignSubFields('secondaryLicense', key, { dateStarted })}
              displayError={displayError && checkAssociatedEmpty(value)}
            />
          </div>
        ))}
      </div>
      <NumberField
        className={classes.input}
        style={{ top: 1438, left: 118, width: 375, fontSize: 18 }}
        value={fields.totalAnnualCompensation}
        placeholder="Total Annual Compensation"
        onChange={totalAnnualCompensation => setValue('totalAnnualCompensation', totalAnnualCompensation)}
        displayError={displayError}
      />
      <NumberField
        className={classes.input}
        style={{ top: 1438, left: 550, fontSize: 18 }}
        value={fields.totalNoOfficers}
        placeholder="Total No. of Officers"
        onChange={totalNoOfficers => setValue('totalNoOfficers', totalNoOfficers)}
        displayError={displayError}
      />
      <NumberField
        className={classes.input}
        style={{ top: 1438, left: 790, fontSize: 18 }}
        value={fields.totalNoRank}
        placeholder="Total No. of Rank & File Employees"
        onChange={totalNoRank => setValue('totalNoRank', totalNoRank)}
        displayError={displayError}
      />
      <NumberField
        className={classes.input}
        style={{ top: 1438, left: 1009, fontSize: 18 }}
        value={fields.totalManpower}
        placeholder="Total Manpower Complement"
        onChange={totalManpower => setValue('totalManpower', totalManpower)}
        displayError={displayError}
      />
    </div>
  );
};

Page8.defaultProps = {
  companyName: '',
  fields: {},
  setValue: () => {},
  displayError: false,
};

Page8.propTypes = {
  companyName: PropTypes.string,
  fields: PropTypes.shape(),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

const mapStateToProps = ({ initialData: { companyName } }) => ({
  companyName,
});

export default connect(mapStateToProps)(Page8);
