import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/styles';

import {
  PageEditTextField as TextField,
  PageEditDatePicker as CustomDatePicker,
  PageEditNumberField as NumberField,
} from '../FormComponents';

const imgSrc = require('../../images/Page_9_GIS.png');

const DatePicker = withStyles({
  root: {
    '&': {
      margin: 0,
    },
    '& > .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& > .MuiOutlinedInput-adornedEnd > .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
    '& > .MuiOutlinedInput-adornedEnd > .MuiInputAdornment-positionEnd > .MuiButtonBase-root': {
      paddingLeft: 0,
    },
  },
})(CustomDatePicker);

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'fit-content',
    position: 'relative',
  },
  input: {
    position: 'absolute',
    margin: 0,
  },
  rowContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
});

const Page9 = ({ companyName, fields, setValue, displayError }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <img src={imgSrc} alt="Page 8 GIS" />
      <TextField
        className={classes.input}
        style={{ top: 171, left: 140, width: 323 }}
        value={fields.name}
        placeholder="Name"
        onChange={name => setValue('name', name)}
        displayError={displayError}
      />
      <Typography className={classes.input} style={{ top: 179, left: 728, fontSize: 20 }}>
        {companyName}
      </Typography>
      <div className={`${classes.input} ${classes.rowContainer}`} style={{ top: 636, left: 214 }}>
        <NumberField
          style={{ width: 41, fontSize: 18, textAlign: 'center', marginRight: 75 }}
          value={fields.day}
          placeholder="DD"
          onChange={day => setValue('day', day)}
          displayError={displayError}
        />
        <DatePicker
          style={{ width: 130, marginRight: 35 }}
          value={fields.month}
          placeholder="Month"
          onChange={month => setValue('month', month)}
          displayError={displayError}
          views={['month']}
          format="MMMM"
        />
        <NumberField
          style={{ width: 41, fontSize: 18, textAlign: 'center', marginRight: 30 }}
          value={fields.year}
          placeholder="YY"
          onChange={year => setValue('year', year)}
          displayError={displayError}
        />
        <TextField
          style={{ width: 222 }}
          value={fields.location}
          placeholder="Location"
          onChange={location => setValue('location', location)}
          displayError={displayError}
        />
      </div>
    </div>
  );
};

Page9.defaultProps = {
  companyName: '',
  fields: {},
  setValue: () => {},
  displayError: false,
};

Page9.propTypes = {
  companyName: PropTypes.string,
  fields: PropTypes.shape(),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

const mapStateToProps = ({ initialData: { companyName } }) => ({
  companyName,
});

export default connect(mapStateToProps)(Page9);
