export default {
  authorizedCapitalStock: {},
  subscribedCapital: {
    filipino: {},
    foreign: {},
  },
  paidupCapital: {
    filipino: {},
    foreign: {},
  },
  totals: {
    authorizedCapitalStock: null,
    subscribedCapital: {
      filipino: null,
      foreign: null,
    },
    paidupCapital: {
      filipino: null,
      foreign: null,
    },
  },
  percentages: {
    subscribedCapital: {
      filipino: null,
      foreign: null,
    },
    paidupCapital: {
      filipino: null,
      foreign: null,
    },
  },
  percentageRoundOff: false,
};
