import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Affiliates from './Affiliates';
import CapitalStock from './CapitalStock';
import SubscribedCapital from './SubscribedCapital';
import PaidUpCapital from './PaidUpCapital';

const MultipleFields = ({
  type,
  style,
  onChange,
  count,
  values,
  options,
  displayError,
  percentageRoundOff,
  ...props
}) => {
  const renderFields = () => {
    const commonProps = {
      onChange,
      displayError,
    };

    switch (type) {
      case 'affiliates': {
        return _.times(count, index => (
          <Affiliates onChange={onChange} index={index} key={index} value={values[index]} />
        ));
      }
      case 'capitalStock': {
        return _.times(count, index => (
          <CapitalStock {...commonProps} index={index} key={index} value={values[index]} />
        ));
      }
      case 'subscribedCapital': {
        return _.times(count, index => (
          <SubscribedCapital
            {...commonProps}
            index={index}
            key={index}
            value={values[index]}
            percentageRoundOff={percentageRoundOff}
          />
        ));
      }
      case 'foreignSubscribedCapital': {
        return _.times(count, index => (
          <SubscribedCapital
            {...commonProps}
            foreign
            index={index}
            key={index}
            value={values[index]}
            percentageRoundOff={percentageRoundOff}
          />
        ));
      }
      case 'paidUpCapital': {
        return _.times(count, index => (
          <PaidUpCapital
            {...commonProps}
            index={index}
            key={index}
            value={values[index]}
            percentageRoundOff={percentageRoundOff}
          />
        ));
      }
      case 'paidUpCapitalForeign': {
        return _.times(count, index => (
          <PaidUpCapital
            {...commonProps}
            foreign
            index={index}
            key={index}
            value={values[index]}
            percentageRoundOff={percentageRoundOff}
          />
        ));
      }
      default:
        return null;
    }
  };

  return (
    <div {...props} style={style}>
      {renderFields()}
    </div>
  );
};

MultipleFields.defaultProps = {
  type: '',
  style: {},
  onChange: () => {},
  count: 1,
  values: {},
  options: [],
  displayError: false,
  percentageRoundOff: false,
};

MultipleFields.propTypes = {
  type: PropTypes.string,
  style: PropTypes.shape(),
  onChange: PropTypes.func,
  count: PropTypes.number,
  values: PropTypes.shape(),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.string,
      style: PropTypes.shape(),
    }),
  ),
  displayError: PropTypes.bool,
  percentageRoundOff: PropTypes.bool,
};

export default MultipleFields;
