export default {
  investmentInAnotherCorporation: {
    stocks: {
      amount: '',
      date: null,
    },
    bonds: {
      amount: '',
      date: null,
    },
    loans: {
      amount: '',
      date: null,
    },
    governmentTreasuryBills: {
      amount: '',
      date: null,
    },
    others: {
      amount: '',
      date: null,
    },
  },
  investmentInSecondaryPurposes: {
    name: '',
    boardResolutionDate: null,
    stockholderRatificationDate: null,
  },
  treasuryShares: {
    name: '',
    numberOfShares: '',
    percentage: '',
  },
  unrestrictedRetainedEarnings: '',
  dividendsDeclared: {
    cash: {
      amount: '',
      date: null,
    },
    stock: {
      amount: '',
      date: null,
    },
    property: {
      amount: '',
      date: null,
    },
    total: {
      amount: '',
    },
  },
  additionalShares: {},
  secondaryLicense: {
    sec: {
      type: '',
      dateIssued: null,
      dateStarted: null,
    },
    bsp: {
      type: '',
      dateIssued: null,
      dateStarted: null,
    },
    ic: {
      type: '',
      dateIssued: null,
      dateStarted: null,
    },
  },
  totalAnnualCompensation: '',
  totalNoOfficers: '',
  totalNoRank: '',
  totalManpower: '',
};
