import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Checkbox } from '../../FormComponents';

const styles = makeStyles({
  input: {
    position: 'absolute',
    margin: 0,
  },
});

const Item2 = ({ fields, onChange }) => {
  const classes = styles();

  return (
    <>
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 626 }}
        checked={fields.insuranceCompanies}
        onChange={insuranceCompanies => onChange('insuranceCompanies', insuranceCompanies)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 651 }}
        checked={fields.insuranceAgents}
        onChange={insuranceAgents => onChange('insuranceAgents', insuranceAgents)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 678 }}
        checked={fields.insuranceBrokers}
        onChange={insuranceBrokers => onChange('insuranceBrokers', insuranceBrokers)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 703 }}
        checked={fields.professionalReinsurers}
        onChange={professionalReinsurers => onChange('professionalReinsurers', professionalReinsurers)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 731 }}
        checked={fields.reinsuranceBrokers}
        onChange={reinsuranceBrokers => onChange('reinsuranceBrokers', reinsuranceBrokers)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 758 }}
        checked={fields.holdingCompanies}
        onChange={holdingCompanies => onChange('holdingCompanies', holdingCompanies)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 783 }}
        checked={fields.holdingCompanySystems}
        onChange={holdingCompanySystems => onChange('holdingCompanySystems', holdingCompanySystems)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 811 }}
        checked={fields.preNeedCompanies}
        onChange={preNeedCompanies => onChange('preNeedCompanies', preNeedCompanies)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 838 }}
        checked={fields.mutualBenefitAssociation}
        onChange={mutualBenefitAssociation => onChange('mutualBenefitAssociation', mutualBenefitAssociation)}
      />
      <Checkbox
        className={classes.input}
        style={{ left: 129, top: 863 }}
        checked={fields.otherPersons}
        onChange={otherPersons => onChange('otherPersons', otherPersons)}
      />
    </>
  );
};

Item2.defaultProps = {
  fields: {},
  onChange: () => {},
};

Item2.propTypes = {
  fields: PropTypes.shape(),
  onChange: PropTypes.func,
};

export default Item2;
