import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import { BeneficialOwners } from './BODComponents';

const imgSrc = require('../../images/BOD_GIS.png');

const styles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 'fit-content',
    position: 'relative',
  },
  input: {
    position: 'absolute',
    margin: 0,
  },
});

const BOD = ({ companyName, secRegistrationNumber, fields, setValue, displayError }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <img src={imgSrc} alt="BOD GIS" />
      <Typography className={classes.input} style={{ left: 250, top: 112 }}>
        {companyName}
      </Typography>
      <Typography className={classes.input} style={{ left: 322, top: 90 }}>
        {secRegistrationNumber}
      </Typography>
      <BeneficialOwners
        className={classes.input}
        style={{ top: 1189 }}
        beneficialOwners={fields.beneficialOwners}
        setValue={beneficialOwners => setValue('beneficialOwners', beneficialOwners)}
        displayError={displayError}
      />
    </div>
  );
};

BOD.defaultProps = {
  companyName: '',
  secRegistrationNumber: '',
  fields: {},
  setValue: () => {},
  displayError: false,
};

BOD.propTypes = {
  companyName: PropTypes.string,
  secRegistrationNumber: PropTypes.string,
  fields: PropTypes.shape(),
  setValue: PropTypes.func,
  displayError: PropTypes.bool,
};

const mapStateToProps = ({ initialData: { companyName }, fields: { page1 } }) => ({
  companyName,
  secRegistrationNumber: page1.secRegistrationNumber,
});

export default connect(mapStateToProps)(BOD);
