import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import DependentFields from '../DependentFields';
import { numberFormat } from '../CustomComponents';

import { hasInput, convertToNumber } from '../../services/helpers';

const styles = () => ({
  container: {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginBottom: 3.5,
  },
});

class CapitalStock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typeOfShare: props.value.typeOfShare,
      numberOfShares: props.value.numberOfShares,
      parStatedValue: props.value.parStatedValue,
      total: props.value.total,
    };
  }

  computeTotal = field => {
    if (_.includes(['numberOfShares', 'parStatedValue'], field)) {
      if (hasInput(this.state.numberOfShares) && hasInput(this.state.parStatedValue)) {
        return _.multiply(convertToNumber(this.state.numberOfShares), convertToNumber(this.state.parStatedValue));
      }
      return null;
    }
    return this.state.total;
  };

  render = () => (
    <div className={this.props.classes.container}>
      <DependentFields
        options={[
          { type: 'text', field: 'typeOfShare', value: this.state.typeOfShare, style: { width: 164, marginRight: 30 } },
          {
            type: 'number',
            field: 'numberOfShares',
            value: this.state.numberOfShares,
            style: { width: 90, marginRight: 88 },
          },
          {
            type: 'number',
            field: 'parStatedValue',
            value: this.state.parStatedValue,
            style: { width: 90, marginRight: 107 },
          },
        ]}
        stock
        onChange={(field, value) => {
          this.setState({ [field]: value }, () => {
            const total = this.computeTotal(field);
            this.setState({ total }, () => {
              this.props.onChange({ [this.props.index]: this.state });
            });
          });
        }}
        displayError={this.props.displayError}
      />
      <Typography style={{ lineHeight: 1, width: 300, textAlign: 'right' }}>
        {numberFormat(this.state.total)}
      </Typography>
    </div>
  );
}

CapitalStock.defaultProps = {
  value: {},
  onChange: () => {},
  index: 0,
  displayError: false,
};

CapitalStock.propTypes = {
  classes: PropTypes.shape().isRequired,
  value: PropTypes.shape({
    typeOfShare: PropTypes.string,
    numberOfShares: PropTypes.string,
    parStatedValue: PropTypes.string,
    total: PropTypes.number,
  }),
  onChange: PropTypes.func,
  index: PropTypes.number,
  displayError: PropTypes.bool,
};

export default withStyles(styles)(CapitalStock);
